import {
  LISTA_STATUS_CAMPANHA,
  TIPO_ENVIO_NOVO_EMAIL,
  FILTRO_STATUS_RESPOSTA,
  FILTRO_STATUS_ENVIO,
  LISTA_TIPO_DE_LISTA,
  LISTA_STATUS_LISTA_DINAMICA,
} from "../../../cadencia-emails/constantesCadenciaEmails";

export const RESET_PAGE = -1;

const getInitialFiltros = () => ({
  busca: "",
  ordenacao: "Lista",
  tipoOrdenacao: "asc",
  campanhas: "all",
  preVendedores: "all",
  statusCampanha: "all",
  filtroTipoLista: "all",
  filtroStatusLista: "all",
  campanhaId: "",
  statusEnvio: "all",
  statusResposta: "",
  etapasCampanha: "all",
  pagina: RESET_PAGE,
  hasMore: true,
});

export const zerarFiltros = state => ({
  busca: "",
  ordenacao: "Lista",
  tipoOrdenacao: "asc",
  campanhas: "all",
  preVendedores: "all",
  statusCampanha: "all",
  filtroTipoLista: "all",
  filtroStatusLista: "all",
  statusResposta: "all",
  statusEnvio: "all",
  etapasCampanha: state.listasAuxiliares.etapasCampanha ? "all" : [],
  pagina: RESET_PAGE,
  hasMore: true,
  campanhaId: state.filtros.campanhaId,
});

export const getInitialEtapaCampanha = () => ({
  nome: "",
  unidadeTempo: "",
  quantidadeTempoEtapa: 0,
  condicao: "",
  tipoEnvio: TIPO_ENVIO_NOVO_EMAIL,
  templateEmailId: "",
});

const getInitialCampanha = () => ({
  nome: "",
  flEnvioFds: null,
  remetenteId: null,
  listaCadenciaEmailsId: null,
  etapaCampanhaList: [getInitialEtapaCampanha()],
  errosValidacaoNovaCampanha: null,
  quantidadeEtapasPermitidas: null,
  exibeOpcaoMinutos: false,
});

const lista = () => ({
  id: null,
  nome: "",
  tipoLista: "",
  contatos: [],
  podeEditarLista: true,
  tooltipEditar: "Editar",
  responsavel: "",
  statusCampanhaId: null,
  campanhaNome: "",
});

export const getInitalDetalheLista = () => ({
  isLoading: null,
  dados: lista(),
});

const returnInitialState = () => ({
  isLoading: false,
  filtros: getInitialFiltros(),
  filtrosTemporarios: {
    statusEnvio: null,
    statusResposta: null,
  },
  listasAuxiliares: {
    isLoading: null,
    statusCampanha: LISTA_STATUS_CAMPANHA,
    filtroTipoLista: LISTA_TIPO_DE_LISTA,
    filtroStatusLista: LISTA_STATUS_LISTA_DINAMICA,
    campanhas: [],
    preVendedores: [],
    listas: [],
    templates: [],
    statusResposta: FILTRO_STATUS_RESPOSTA,
    statusEnvio: FILTRO_STATUS_ENVIO,
    etapasCampanha: [],
  },
  tabs: {
    campanhas: {
      items: [],
      isLoading: false,
    },
    listas: {
      items: [],
      isLoading: false,
    },
    templates: {
      items: [],
      isLoading: false,
    },
    descadastros: {
      items: [],
      isLoading: false,
    },
  },
  detalhes: {
    lista: getInitalDetalheLista(),
    campanha: {
      isLoading: null,
      dados: getInitialCampanha(),
    },
  },

  modals: {
    selecionaTipoLista: { open: false },
    contato: { open: false, contatoEdicao: null },
    importarContatos: { open: false, contatos: [] },
    distribuirEntrePreVendedores: { open: false },
    excluirDescadastro: { open: false },
    inicioEtapa: { open: false, indexEtapa: null },
    novoTemplate: {
      open: false,
      readOnly: false,
      podeEditar: true,
      templateId: null,
      textoAlternadoParaHtml: false,
      erroVariaveis: "",
      duplicarTemplate: false,
    },
    buscarContatosBaseLeads: { open: false, contatos: [] },
    descadastros: { open: false },
    adicionarContato: { open: false },
    campanhas: { open: false, lista: [] },
    currentEmailVisualizacao: null,
  },
  detalheCampanha: {
    titulo: null,
    campanhaId: null,
    filtroStatusResposta: null,
    filtroStatusEnvio: null,
    respostas: {
      isLoading: false,
      items: [],
      respostaId: null,
    },
    envios: {
      isLoading: true,
      items: [],
      quantidadeTotal: null,
      todosContatosSelecionados: false,
    },
    campanhaContatos: {
      isLoading: true,
      data: {},
    },
  },
  interesseAtividade: {
    respostaId: null,
  },
  configuracoes: {
    dominio: {
      nome: "",
    },
  },
});

export default returnInitialState;
