import React, { useState } from "react";
import { Dialog, withStyles, Tooltip } from "@material-ui/core";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import EmailIcon from "@material-ui/icons/Email";
import cn from "classnames";

import ModalBuscadorEmail from "./ModalBuscadorEmail";
import { ActionButton } from "../../../../../../components";

BuscadorEmail.propTypes = {
  classes: PropTypes.object,
  leadId: PropTypes.string,
  contato: PropTypes.object,
  lead: PropTypes.object,
  creditos: PropTypes.number,
  mostrarLabel: PropTypes.bool,
};

const VALOR_MINIMO_CREDITO = 1;

function BuscadorEmail({ creditos, classes, contato, leadId, lead, mostrarLabel = false }) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(o => !o);
  const semAcao = () => false;
  const intl = useIntl();

  return (
    <>
      {mostrarLabel ? (
        <Tooltip title={intl.formatMessage({ defaultMessage: "Não foram encontrados e-mails" })}>
          {actionButtonEncontrarEmails(intl, semAcao, cn(classes.secundario, classes.semAcao))}
        </Tooltip>
      ) : (
        <>
          {creditos < VALOR_MINIMO_CREDITO ? (
            <>
              <Tooltip
                title={intl.formatMessage({
                  defaultMessage: "Você não tem mais créditos para efetuar buscas",
                })}
              >
                {actionButtonEncontrarEmails(intl, semAcao, cn(classes.secundario, classes.semAcao))}
              </Tooltip>
            </>
          ) : (
            <>
              {actionButtonEncontrarEmails(intl, toggle, classes.primario)}
              <Dialog maxWidth="sm" fullWidth open={open} onClose={toggle}>
                <ModalBuscadorEmail onClose={toggle} contato={contato} leadId={leadId} lead={lead} />
              </Dialog>
            </>
          )}
        </>
      )}
    </>
  );
}

function actionButtonEncontrarEmails(intl, toggle, classe) {
  return (
    <ActionButton
      id="btn-encontrar-emails"
      type="button"
      className={classe}
      label={intl.formatMessage({ defaultMessage: "Encontrar E-mails" })}
      onClick={toggle}
      icon={<EmailIcon style={{ marginRight: 4 }} />}
      style={{ paddingLeft: 0 }}
    />
  );
}

export default withStyles({
  error: { height: 21, width: 21, cursor: "default" },
  success: { color: "green", height: 21, width: 21, cursor: "default " },
  icon: { height: 21, width: 21, cursor: "pointer" },
  iconDisabled: { color: "rgba(0,0,0,0.54)", height: 21, width: 21, cursor: "default" },
  primario: { color: "#457AB7" },
  secundario: { color: "rgba(0, 0, 0, 0.38)" },
  semAcao: { cursor: "initial" },
})(BuscadorEmail);
