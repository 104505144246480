import React from "react";
import PropTypes from "prop-types";
import { Avatar, Divider, withStyles, Typography, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import useSWR from "swr";
import { useIntl } from "react-intl";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";

import { Flex, Tipografia } from "../../../../_common";
import { FoneNovo } from "../../../../components";
import { ESTADO_MINERACAO } from "../../../../_common/constantes";
import BuscadorEmail from "./components/BuscadorEmail";
import { formatPhoneMask } from "../../../../_common/utils/phone";
import { buscarListaContatos, salvarListaContatos } from "../../../../store/reducers/detalhesLead/detalhesLeadActions";
import { usePermission, useTipoPlanoSpotter } from "../../../../hooks";
import PERMISSIONS from "../../../../_common/permissions";

import RowEmail from "./components/RowEmail";
import RowMensageiro from "./components/RowMensageiro";
import MenuLGPD from "./components/MenuLGPD";

Contatos.propTypes = {
  classes: PropTypes.object,
  leadId: PropTypes.string,
  listaContatos: PropTypes.array,
  lead: PropTypes.object,
  setModalLigacaoIsOpen: PropTypes.func,
  setContatoSelecionado: PropTypes.func,
  setIsModalEditarContatoOpen: PropTypes.func,
  limiteEdicaoMultiplaContatos: PropTypes.number,
};

const mapDispatchToProps = dispatch => ({
  buscarLista: id => dispatch(buscarListaContatos(id)),
  salvarLista: contato => dispatch(salvarListaContatos(contato)),
});

const mapStateToProps = state => ({
  listaContatos: state.detalhesLead.contatos,
});

function Contatos({
  classes,
  lead,
  leadId,
  listaContatos,
  setModalLigacaoIsOpen,
  setContatoSelecionado,
  setIsModalEditarContatoOpen,
  limiteEdicaoMultiplaContatos,
}) {
  const { data: creditos } = useSWR("/api/pipeline/FuncionalidadePlano/CreditosBuscador");
  const intl = useIntl();
  const podeMinerar = usePermission(PERMISSIONS.ENCONTRARCONTATOS, lead);
  const isSpotterFull = useTipoPlanoSpotter();

  const openModal = contato => {
    setContatoSelecionado(contato);
    setIsModalEditarContatoOpen(true);
  };

  return (
    <Flex flexDirection="column" className={classes.cardContainer}>
      {!!listaContatos?.length &&
        listaContatos.map((contato, index) => (
          <Flex key={index} flexDirection="column">
            <Flex className={classes.container} justifyContent="space-between">
              <Flex className={classes.containerInfoUsuario} justifyContent="space-between">
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
                <Flex flexDirection="column" justifyContent="space-between">
                  <Typography variant="body2" className={classes.nome}>
                    {contato.nome}
                  </Typography>
                  <Typography className={classes.cargo}>{contato.cargo}</Typography>
                </Flex>
              </Flex>
              {contato.lgpdHabilitada && (
                <MenuLGPD
                  coletaDados={contato.coletaDados}
                  envioComunicacao={contato.envioComunicacao}
                  dadosVoz={contato.dadosVoz}
                />
              )}
              {listaContatos.length > limiteEdicaoMultiplaContatos && (
                <IconButton id="btn-editar-contato" onClick={() => openModal(contato)}>
                  <EditIcon />
                </IconButton>
              )}
            </Flex>
            <Flex className={classes.containerTotal}>
              <Flex flexDirection="column" className={classes.containerDireita}>
                {isSpotterFull && (
                  <>
                    {!contato.emails[0] && (
                      <Flex>
                        {contato.mineracao === ESTADO_MINERACAO.SEM_MINERACAO &&
                          podeMinerar && (
                            <BuscadorEmail creditos={creditos} contato={contato} leadId={leadId} lead={lead} />
                          )}

                        {contato.mineracao === ESTADO_MINERACAO.EM_PROGRESSO && (
                          <Typography color="textSecondary">
                            {intl.formatMessage({ defaultMessage: "BUSCANDO..." })}
                          </Typography>
                        )}

                        {contato.mineracao === ESTADO_MINERACAO.MINERADO && <BuscadorEmail mostrarLabel />}
                      </Flex>
                    )}
                  </>
                )}
                {contato.emails.map(email => (
                  <RowEmail
                    emailInfo={{
                      name: contato.nome,
                      email: email.endereco,
                      id: email.id,
                      valido: email.valido,
                      validando: email.validando,
                      mineracao: email.mineracao,
                      probabilidadeEntrega: email.probabilidadeEntrega,
                      contatoPrincipal: email.principal,
                    }}
                    contato={{ ...contato }}
                    key={email.id}
                  />
                ))}
                {contato.telefones.map(telefone => (
                  <FoneNovo
                    value={formatPhoneMask({ ddi: telefone.ddi, phone: telefone.numero })}
                    ddi={telefone.ddi}
                    leadId={leadId}
                    telefonePrincipal={telefone.principal}
                    tipo={String(telefone.tipo.id)}
                    key={telefone.id}
                    setModalLigacaoIsOpen={setModalLigacaoIsOpen}
                  />
                ))}
              </Flex>
              <Flex flexDirection="column" className={classes.containerEsquerda}>
                {contato.mensageiros.map(mensageiro => <RowMensageiro key={mensageiro.id} mensageiro={mensageiro} />)}
              </Flex>
            </Flex>
            <Divider className={classes.divider} />
          </Flex>
        ))}

      {!listaContatos?.length && (
        <>
          <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
            {intl.formatMessage({ defaultMessage: "Nenhum contato cadastrado" })}
          </Tipografia>
          <Divider className={classes.divider} />
        </>
      )}
    </Flex>
  );
}

const enhance = compose(
  withStyles({
    cardContainer: { padding: "0px 20px" },
    container: { padding: "8px 0", width: "100%" },
    containerInfoUsuario: { paddingBottom: 8 },
    avatar: { backgroundColor: "rgba(0, 0, 0, 0.38)", marginRight: 8 },
    nome: { fontSize: 14, fontWeight: 500, lineHeight: "20.02px", letterSpacing: "0.17px" },
    cargo: { fontSize: 12, fontWeight: 400, lineHeight: "12px", letterSpacing: "0.15px" },
    containerTotal: { padding: "8px 0px" },
    containerDireita: { width: "70%" },
    containerEsquerda: { width: "30%", paddingLeft: 16 },
    italic: { fontStyle: "italic", padding: "0 20px 6px 28px" },
    divider: { marginTop: 8, marginBottom: 8 },
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(Contatos);
