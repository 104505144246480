import { defineMessage } from "react-intl";
import BarChart from "@material-ui/icons/BarChart";
import PieChart from "@material-ui/icons/PieChart";
import TableChart from "@material-ui/icons/TableChart";
import ShowChart from "@material-ui/icons/ShowChart";
import LooksOne from "@material-ui/icons/LooksOne";
import moment from "moment";
import { PERIODO } from "../../_common/constantes";

export const CARD_RELATORIO = {
  // #region Gerentes
  PRODUTIVIDADE_GERENCIAL: 1,
  PRODUCAO_PRE_VENDA: 2,
  METRICAS_PRE_VENDA: 3,
  PRODUCAO_VENDA: 4,
  METRICAS_VENDA: 5,
  QUALIDADE_REUNIOES_GERENCIAL: 6,
  QUALIFICACOES_GERENCIAL: 7,
  FEEDBACK_LIGACAO_GERENCIAL: 8,
  SAFRA: 21,
  PREVISAO_NEGOCIOS_VALOR: 27,
  PREVISAO_NEGOCIOS_QUANTIDADE: 28,
  PREVISAO_NEGOCIOS_MES: 29,
  // #endregion

  // #region PVs
  TEMPO_LIGACAO: 9,
  LIGACOES_ATENDIDAS: 10,
  AGENDAMENTOS: 11,
  PONTOS: 12,
  QUALIDADE_REUNICOES_PERCENTUAL: 13,
  ATIVIDADES: 14,
  ATIVIDADES_ATRASADAS: 15,
  FEEDBACKS: 16,
  PRODUTIVIDADE: 17,
  QUALIFICACOES: 18,
  QUALIDADE_REUNIOES: 19,
  FEEDBACK_LIGACAO: 20,
  QUALIDADE_REUNIOES_SQL: 22, // #endregion // src\dashboard\components\TempoLigacoes.js // src\dashboard\components\QuantidadeLigacoes.js // src\dashboard\components\QuantidadeAgendamentos.js // src\dashboard\components\Pontuacao.js // src\dashboard\components\QualidadeReunioes.js // src\dashboard\components\Atividades.js // src\dashboard\components\AtividadesAtrasadas.js // src\dashboard\components\Feedback\Feedback.js // src\dashboard\components\Conversoes.js // src\dashboard\components\Qualificacoes.js // src\dashboard\components\Qualificacoes.js

  METAS: 23,
  TEMPO_MEDIO: 24,
  META_VENDEDOR_MENSAL: 25,
  META_PRE_VENDEDOR_MENSAL: 26,
};

export const TAMANHO_CARD_RELATORIO = {
  CARD: { width: 2, height: 2 },
  PAINEL: { width: 6, height: 6 },
  PAINEL_HORIZONTAL: { width: 12, height: 6 },
  PAINEL_VERTICAL: { width: 6, height: 12 },
};

export const PERIODOS_DASHBOARD = (format = true) => {
  const formatar = valor => (format ? valor.format("YYYY-MM-DD") : valor);

  return {
    SEMANA: [formatar(moment().startOf(PERIODO.SEMANA)), formatar(moment().endOf(PERIODO.SEMANA))],
    MES: [formatar(moment().startOf(PERIODO.MES)), formatar(moment().endOf(PERIODO.MES))],
    HOJE: [formatar(moment()), formatar(moment())],
    ONTEM: [formatar(moment().subtract(1, PERIODO.DIA)), formatar(moment().subtract(1, PERIODO.DIA))],
    PERSONALIZADO: periodoPadrao => [
      formatar(moment(periodoPadrao.periodoInicio)),
      formatar(moment(periodoPadrao.periodoFim)),
    ],
  };
};

const charts = {
  bar: BarChart,
  pie: PieChart,
  table: TableChart,
  line: ShowChart,
  number: LooksOne,
};

export const DETALHES_CARD_RELATORIO = {
  [CARD_RELATORIO.METAS]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({ defaultMessage: "Tabela de metas por perfil" }),
    title: defineMessage({
      defaultMessage: "Metas",
    }),
    Chart: charts.table,
    disableRegra: true,
    gerencial: true,
  },
  [CARD_RELATORIO.TEMPO_MEDIO]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage(
      // flag temporária pra avaliarmos como prosseguir
      { defaultMessage: "Tempo médio por origem" }
    ),
    title: defineMessage({
      defaultMessage: "Tempo Médio Por Origem",
    }),
    Chart: charts.table,
    disableRegra: false,
    gerencial: true,
  },
  [CARD_RELATORIO.PRODUTIVIDADE_GERENCIAL]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage(
      // flag temporária pra avaliarmos como prosseguir
      {
        defaultMessage: "Gráfico de colunas mostrando a quantidade de leads convertidos em todas as etapas do funil",
      }
    ),
    title: defineMessage({
      defaultMessage: "Atividade no Funil (gerencial)",
    }),
    Chart: charts.bar,
    gerencial: true,
  },
  [CARD_RELATORIO.PRODUCAO_PRE_VENDA]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Tabela com a quantidade de leads convertidos em cada etapa por pré-vendedor",
    }),
    title: defineMessage({
      defaultMessage: "Produção da Pré-Venda",
    }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.METRICAS_PRE_VENDA]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Tabela com métricas de ligação e principais eventos do funil por pré-vendedor",
    }),
    title: defineMessage({
      defaultMessage: "Métricas da Pré-Venda",
    }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.PRODUCAO_VENDA]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Tabela com a quantidade de leads convertidos em cada etapa por vendedor",
    }),
    title: defineMessage({
      defaultMessage: "Produção dos Vendedores",
    }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.METRICAS_VENDA]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Tabela com métricas de ligação e principais eventos do funil por vendedor",
    }),
    title: defineMessage({
      defaultMessage: "Métricas da Venda",
    }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.QUALIDADE_REUNIOES_GERENCIAL]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL,
    description: defineMessage({
      defaultMessage:
        "Gráfico circular mostrando as qualificações em filtros de feedback de reunião considerando o período de realização das reuniões",
    }),
    title: defineMessage({
      defaultMessage: "Qualidade das Reuniões (gerencial)",
    }),
    Chart: charts.pie,
    gerencial: true,
  },
  [CARD_RELATORIO.QUALIFICACOES_GERENCIAL]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL,
    description: defineMessage({
      defaultMessage:
        "Gráfico circular que permite visualizar as temperaturas em qualquer filtro do funil considerando o período de aplicação do respectivo filtro",
    }),
    title: defineMessage({
      defaultMessage: "Temperaturas (gerencial)",
    }),
    Chart: charts.pie,
    gerencial: true,
  },
  [CARD_RELATORIO.FEEDBACK_LIGACAO_GERENCIAL]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Lista de solicitações e envios de feedbacks de ligação",
    }),
    title: defineMessage({
      defaultMessage: "Feedbacks de Ligação (gerencial)",
    }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.TEMPO_LIGACAO]: {
    size: TAMANHO_CARD_RELATORIO.CARD,
    description: defineMessage({
      defaultMessage:
        "Acompanhamento do tempo total gasto nas ligações realizadas pelo pré-vendedor, sempre que havendo integração com Voip, relacionada a meta do dia",
    }),
    title: defineMessage({
      defaultMessage: "Tempo De Ligação",
    }),
    Chart: charts.number,
    gerencial: false,
  },
  [CARD_RELATORIO.LIGACOES_ATENDIDAS]: {
    size: TAMANHO_CARD_RELATORIO.CARD,
    description: defineMessage({
      defaultMessage:
        "Expõe o número de ligações que os pré-vendedores realizaram e que foram atendidas, relacionada a meta do dia",
    }),
    title: defineMessage({
      defaultMessage: "Ligações Atendidas (conexões)",
    }),
    Chart: charts.number,
    gerencial: false,
  },
  [CARD_RELATORIO.AGENDAMENTOS]: {
    size: TAMANHO_CARD_RELATORIO.CARD,
    description: defineMessage({
      defaultMessage: "Apresenta a quantidade de agendamento realizado ao longo do dia, relacionado a meta estipulada",
    }),
    title: defineMessage({
      defaultMessage: "Agendamentos",
    }),
    Chart: charts.number,
    gerencial: false,
  },
  [CARD_RELATORIO.PONTOS]: {
    size: TAMANHO_CARD_RELATORIO.CARD,
    description: defineMessage({
      defaultMessage: "Exibe a pontuação que o pré-vendedor tem no mês vigente, relacionado a meta estipulada",
    }),
    title: defineMessage({
      defaultMessage: "Pontos",
    }),
    Chart: charts.number,
    gerencial: false,
  },
  [CARD_RELATORIO.QUALIDADE_REUNICOES_PERCENTUAL]: {
    size: TAMANHO_CARD_RELATORIO.CARD,
    description: defineMessage({
      defaultMessage:
        "Apresenta o percentual das reuniões agendadas que receberam um feedback satisfatório durante o mês vigente",
    }),
    title: defineMessage({
      defaultMessage: "Qualidade das Reuniões",
    }),
    Chart: charts.number,
    gerencial: false,
  },
  [CARD_RELATORIO.ATIVIDADES]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_VERTICAL,
    description: defineMessage({
      defaultMessage: "Oferece a gestão das atividades do dia, dando a possibilidade de concluir, editar e excluí-las",
    }),
    title: defineMessage({
      defaultMessage: "Atividades",
    }),
    Chart: charts.table,
    gerencial: false,
  },
  [CARD_RELATORIO.ATIVIDADES_ATRASADAS]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL,
    description: defineMessage({
      defaultMessage:
        "Traz a listagem das atividades que estão em atraso, podendo fazer edições, exclusões, conclusões e ligações",
    }),
    title: defineMessage({
      defaultMessage: "Atividades Atrasadas",
    }),
    Chart: charts.table,
    gerencial: false,
  },
  [CARD_RELATORIO.FEEDBACKS]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL,
    description: defineMessage({
      defaultMessage: "Lista os feedbacks recebidos e pendentes, além de permitir o reenvio pelo dashboard",
    }),
    title: defineMessage({
      defaultMessage: "Feedback",
    }),
    Chart: charts.table,
    gerencial: false,
  },
  [CARD_RELATORIO.PRODUTIVIDADE]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Gráfico de colunas mostrando a quantidade de leads convertidos em todas as etapas do funil",
    }),
    title: defineMessage({
      defaultMessage: "Atividade no Funil",
    }),
    Chart: charts.bar,
    gerencial: false,
  },
  [CARD_RELATORIO.QUALIFICACOES]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL,
    description: defineMessage({
      defaultMessage:
        "Gráfico circular que permite visualizar as temperaturas em qualquer filtro do funil considerando o período de aplicação do respectivo filtro",
    }),
    title: defineMessage({
      defaultMessage: "Temperaturas",
    }),
    Chart: charts.pie,
    gerencial: false,
  },
  [CARD_RELATORIO.QUALIDADE_REUNIOES]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL,
    description: defineMessage({
      defaultMessage:
        "Gráfico circular mostrando as qualificações em filtros de feedback de reunião considerando o período de realização das reuniões",
    }),
    title: defineMessage({
      defaultMessage: "Qualidade das Reuniões",
    }),
    Chart: charts.pie,
    gerencial: false,
  },
  [CARD_RELATORIO.FEEDBACK_LIGACAO]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Exibe os feedbacks realizados pelo gerente acerca da qualidade das ligações e abordagens",
    }),
    title: defineMessage({
      defaultMessage: "Feedbacks de Ligação",
    }),
    Chart: charts.table,
    gerencial: false,
  },
  [CARD_RELATORIO.SAFRA]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage:
        "Gráfico de coluna que indica o estado do funil, considerando o status dos leads cadastrados no período selecionado",
    }),
    title: defineMessage({
      defaultMessage: "Safra",
    }),
    Chart: charts.bar,
    gerencial: true,
  },
  [CARD_RELATORIO.PREVISAO_NEGOCIOS_VALOR]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage:
        "Lista da soma dos produtos sugeridos de acordo com a qualificação, considerando a data de previsão de fechamento",
    }),
    title: defineMessage({ defaultMessage: "Previsão De Negócios Por Qualificação ($)" }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.PREVISAO_NEGOCIOS_QUANTIDADE]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage:
        "Lista a quantidade de leads de acordo com a qualificação, considerando a data de previsão de fechamento",
    }),
    title: defineMessage({ defaultMessage: "Previsão De Negócios Por Qualificação (#)" }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.QUALIDADE_REUNIOES_SQL]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL,
    description: defineMessage({
      defaultMessage:
        "Sempre que  o Sales Qualified Lead - SQL estiver habilitado, é gerado um gráfico circular mostrando as qualificações em filtros de feedback de reunião considerando o período de realização das reuniões",
    }),
    title: defineMessage({
      defaultMessage: "Qualidade das Reuniões S.Q.L",
    }),
    Chart: charts.pie,
    gerencial: true,
  },
  [CARD_RELATORIO.META_VENDEDOR_MENSAL]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Tabela com métricas de ligação e principais eventos do funil por vendedor mensal",
    }),
    title: defineMessage({
      defaultMessage: "Desempenho de vendas - Vendedores",
    }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.META_PRE_VENDEDOR_MENSAL]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage: "Tabela com métricas de ligação e principais eventos do funil por pré-vendedor mensal",
    }),
    title: defineMessage({
      defaultMessage: "Desempenho de vendas - Pré Vendedores",
    }),
    Chart: charts.table,
    gerencial: true,
  },
  [CARD_RELATORIO.PREVISAO_NEGOCIOS_MES]: {
    size: TAMANHO_CARD_RELATORIO.PAINEL_HORIZONTAL,
    description: defineMessage({
      defaultMessage:
        "Lista da soma dos produtos sugeridos de acordo com o mês, considerando a data de previsão de fechamento",
    }),
    title: defineMessage({
      defaultMessage: "Previsão De Negócios Por Mês ($)",
    }),
    Chart: charts.table,
    gerencial: true,
  },
};
