import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Button,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  Collapse,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import useSWR from "swr";
import axios from "axios";
import Help from "@material-ui/icons/Help";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";

import { Flex, Tipografia, createSnackbarAPIException, createSnackbar, AjaxBlackout } from "../../../../../_common";
import injectSheet from "../../../../../_common/hocs/injectSheet/injectSheet";
import Truncate from "../../../../../_common/components/Truncate/Truncate";
import { useUsuario } from "../../../../../hooks";
import createDialogConfirm from "../../../../../_common/utils/dialogConfirm/createDialogConfirm";
import EncontrarEmailsBuscador from "../components/EncontrarEmailsBuscador/EncontrarEmailsBuscador";

ModalBuscarContatos.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  empresa: PropTypes.string,
  leadId: PropTypes.string,
  classes: PropTypes.object,
};

function ModalBuscarContatos({ open, onClose, empresa, leadId, classes }) {
  const intl = useIntl();
  const { data: creditos, mutate } = useSWR("/api/pipeline/FuncionalidadePlano/CreditosBuscador");
  const [contatos, setContatos] = useState([]);
  const [selecionados, setSelecionados] = useState(0);
  const { featureToggles } = useUsuario();
  const [identificador, setIdentificador] = useState(`${crypto.randomUUID()}`);
  const [contatosEmBusca, setContatosEmBusca] = useState([]);
  const colunaNome = featureToggles.rolloutModalBuscadorValidador ? "25%" : "35%";
  const colunaLinkedIn = featureToggles.rolloutModalBuscadorValidador ? "40%" : "60%";
  let listaAtualizada = [];

  useEffect(
    () => {
      setSelecionados(contatos.filter(({ Selected }) => Selected).length);
      listaAtualizada = contatos;
    },
    [contatos]
  );

  useEffect(
    () => {
      if (!open && contatos.length) setContatos([]);
    },
    [open]
  );

  async function onSubmit(values) {
    const contatosEncontrados = await buscarContatos(values.Cargo, values.empresa);
    setContatos(contatosEncontrados);
    if (contatosEncontrados.length) mutate(creditos - 1, false);
  }

  function validate(values) {
    const errors = {};
    const campoObrigatorioMsg = intl.formatMessage({ defaultMessage: "Campo obrigatório *" });
    if (!values.empresa) errors.empresa = campoObrigatorioMsg;
    return errors;
  }

  async function adicionarContatos(contatosSelecionados, close) {
    try {
      AjaxBlackout.Show();
      await axios.post(`/api/pipeline/Lead/Contatos?id=${leadId}&removerNaoEnviados=false`, contatosSelecionados);
      createSnackbar(intl.formatMessage({ defaultMessage: "Os contatos foram adicionados com sucesso." }));
      close(true);
    } catch (err) {
      createSnackbar(intl.formatMessage({ defaultMessage: "Ocorreu um erro ao adicionar os contatos." }));
    } finally {
      AjaxBlackout.Hide();
    }
  }

  const prepararParaAdicionar = () => {
    adicionarContatos(
      contatos.filter(({ Selected }) => Selected).map(contato => ({
        Nome: contato.Nome,
        Cargo: contato.Cargo,
        Principal: false,
        Mensageiros: [
          {
            Usuario: contato.LinkedIn,
            Tipo: { Id: 7 },
          },
        ],
        Emails: contato.emails,
      })),
      onClose
    );
  };

  const verificarContatosParaAdicionar = () => {
    if (contatosEmBusca.length > 0) {
      alertaBuscaNaoConcluida(
        () => prepararParaAdicionar(),
        false,
        intl.formatMessage({ defaultMessage: "Busca não concluída" }),
        intl.formatMessage({
          defaultMessage:
            "Se sair agora, a busca será interrompida e o crédito não será reembolsado. Caso tenha selecionado algum contato, ele será salvo na sua lista de contatos.",
        })
      );
    } else prepararParaAdicionar();
  };

  const alertaBuscaNaoConcluida = (acceptedCallback, refusedCallback, titulo, mensagem) => {
    createDialogConfirm({
      title: titulo,
      text: mensagem,
      fullWidth: true,
      tamanhoMaximo: "sm",
      actions: unmount => (
        <DialogActions>
          <Button
            onClick={() => {
              unmount();
              refusedCallback();
            }}
          >
            {intl.formatMessage({ defaultMessage: "Continuar buscando" })}
          </Button>
          <Button
            className={classes.btnAcaoBuscaNaoConcluida}
            onClick={() => {
              unmount();
              acceptedCallback();
            }}
          >
            {intl.formatMessage({ defaultMessage: "Confirmar e sair" })}
          </Button>
        </DialogActions>
      ),
    });
  };

  async function buscarContatos(cargo, nomeEmpresa) {
    try {
      AjaxBlackout.Show();

      const data = await axios.get(
        `/api/pipeline/Contatos/BuscarLinkedInMentorIA?empresa=${nomeEmpresa}&cargo=${cargo}`
      );
      setIdentificador(`${crypto.randomUUID()}`);
      const contatosEncontrados = data.data || [];
      return contatosEncontrados.map(contato => ({
        Cargo: contato.titulo,
        Nome: contato.nome,
        LinkedIn: contato.link,
        Descricao: contato.descricao,
        Empresa: nomeEmpresa,
        Selected: false,
      }));
    } catch (e) {
      createSnackbarAPIException(e);
      return [];
    } finally {
      AjaxBlackout.Hide();
    }
  }

  const callbackEncontrarEmails = (adicionar, posicao) => {
    const listaEmBusca = contatosEmBusca;
    if (adicionar) {
      listaEmBusca.push(posicao);
      setContatosEmBusca(() => listaEmBusca);
      mutate();
    } else setContatosEmBusca(() => listaEmBusca.filter(i => i !== posicao));
  };

  const adicionarEmailsContato = (posicao, emails) => {
    listaAtualizada = listaAtualizada.map((contato, index) => (index === posicao ? { ...contato, emails } : contato));
    setContatos(listaAtualizada);
  };

  const cancelarBusca = () => {
    if (contatosEmBusca.length > 0) {
      alertaBuscaNaoConcluida(
        () => onClose(false),
        () => false,
        intl.formatMessage({ defaultMessage: "Tem certeza de que deseja cancelar a busca?" }),
        intl.formatMessage({
          defaultMessage: "Se sair agora, você perderá os contatos encontrados e seu crédito não será reembolsado.",
        })
      );
    } else onClose(false);
  };

  return (
    <Flex id="modal-buscar-contatos" flexDirection="column" className={classes.root}>
      <DialogTitle style={{ paddingLeft: "0px" }}>
        {intl.formatMessage({ defaultMessage: "Buscar Contatos" })}
        <Tooltip
          title={intl.formatMessage({
            defaultMessage: "Os resultados da busca são valores aproximados",
          })}
        >
          <Help color="textSecondary" fontSize="small" style={{ marginLeft: 8 }} />
        </Tooltip>
      </DialogTitle>
      <Formik enableReinitialize onSubmit={onSubmit} validate={validate} initialValues={{ empresa, Cargo: "" }}>
        {({ handleReset, handleSubmit, setValues }) => (
          <Form style={{ width: "100%" }}>
            <Flex justifyContent="space-between">
              <Field
                component={TextField}
                name="empresa"
                label={intl.formatMessage({ defaultMessage: "Buscar pelo Nome da empresa *" })}
                id="buscar-contato-empresa"
                placeholder={intl.formatMessage({ defaultMessage: "Ex: Exact Sales" })}
                helperText=" "
                className={classes.input}
              />
              <Field
                component={TextField}
                name="Cargo"
                label={intl.formatMessage({ defaultMessage: "Buscar pelo Cargo" })}
                id="buscar-contato-Cargo"
                placeholder={intl.formatMessage({ defaultMessage: "Ex: Pré-vendedor" })}
                helperText=" "
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        placement="top"
                        title={intl.formatMessage({
                          defaultMessage: "Digite o cargo completo para obter resultados mais precisos",
                        })}
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Flex>

            <Flex justifyContent="space-between" style={{ height: 56, position: "relative" }}>
              <Tipografia style={{ justifySelf: "flex-start", alignSelf: "center" }} cor="darkSecondaryText">
                {intl.formatMessage({ defaultMessage: "{creditos} buscas disponíveis" }, { creditos })}
              </Tipografia>
              <Flex justifyContent="center" alignItems="center">
                <Button
                  className={classes.botao}
                  id="botao-limpar"
                  onClick={() => {
                    handleReset();
                    setValues({ empresa: "", Cargo: "" });
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Limpar" })}
                </Button>
                <Tooltip
                  title={
                    !creditos
                      ? intl.formatMessage({
                          defaultMessage: "Você não possui créditos o suficiente para realizar novas buscas",
                        })
                      : ""
                  }
                >
                  <span>
                    <Button
                      id="botao-buscar"
                      color="primary"
                      className={classes.botao}
                      onClick={handleSubmit}
                      disabled={!creditos}
                      type="submit"
                    >
                      {intl.formatMessage({ defaultMessage: "Buscar" })}
                    </Button>
                  </span>
                </Tooltip>
              </Flex>

              <Collapse in={!!selecionados} timeout={500} className={classes.collapse}>
                <Flex className={classes.countBar} justifyContent="space-between" alignItems="center">
                  <Tipografia cor="lightPrimaryText">
                    {intl.formatMessage({ defaultMessage: "{selecionados} leads selecionados" }, { selecionados })}
                  </Tipografia>
                  <Button
                    className={classes.whiteButton}
                    onClick={() => setContatos(contatos.map(contato => ({ ...contato, Selected: false })))}
                    id="btn-limpar-selecionados"
                  >
                    {intl.formatMessage({ defaultMessage: "Limpar" })}
                  </Button>
                </Flex>
              </Collapse>
            </Flex>
          </Form>
        )}
      </Formik>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "5%" }} padding="checkbox" className={classes.tableCell}>
                <Checkbox
                  id="checkbox-selecionar-todos"
                  color="primary"
                  checked={Boolean(
                    contatos.length &&
                      contatos.map(({ Selected }) => Selected).filter(Boolean).length === contatos.length
                  )}
                  onChange={(e, nv) => {
                    setContatos(contatos.map(contato => ({ ...contato, Selected: nv })));
                  }}
                />
              </TableCell>
              <TableCell style={{ width: colunaNome }} className={classes.tableCell}>
                {intl.formatMessage({ defaultMessage: "Contato/Cargo" })}
              </TableCell>
              <TableCell style={{ width: colunaLinkedIn }} className={classes.tableCell}>
                {intl.formatMessage({ defaultMessage: "LinkedIn" })}
              </TableCell>
              {featureToggles.rolloutModalBuscadorValidador && (
                <TableCell style={{ width: "30%" }} className={classes.tableCell}>
                  {intl.formatMessage({ defaultMessage: "Validador" })}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {contatos.map((contato, index) => (
              <TableRow className={classes.tableRow} hover key={index}>
                <TableCell className={classes.tableCell} padding="checkbox">
                  <Checkbox
                    color="primary"
                    id={`checkbox-contato-[${index}]`}
                    checked={contato.Selected}
                    onChange={() => {
                      setContatos([
                        ...contatos.slice(0, index),
                        { ...contatos[index], Selected: !contatos[index].Selected },
                        ...contatos.slice(index + 1),
                      ]);
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Tipografia id={`modal-nome-contato-${index}`}>{contato.Nome}</Tipografia>
                  <br />
                  <Tooltip title={contato.Cargo}>
                    <Truncate
                      type="notaLegendas"
                      color="darkSecondaryText"
                      numberOfLines={2}
                      id={`modal-cargo-contato-${index}`}
                    >
                      {contato.Cargo}
                    </Truncate>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Tooltip title={contato.LinkedIn}>
                    <Tipografia
                      id={`modal-linkedin-contato-${index}`}
                      url={contato.LinkedIn}
                      urlExterna
                      target="_blank"
                      rel="noreferrer"
                    >
                      {contato.LinkedIn}
                    </Tipografia>
                  </Tooltip>
                </TableCell>
                {featureToggles.rolloutModalBuscadorValidador && (
                  <TableCell className={classes.tableCell}>
                    <EncontrarEmailsBuscador
                      identificador={identificador}
                      posicao={index}
                      contato={contato}
                      atualizarContatos={adicionarEmailsContato}
                      leadId={leadId}
                      callback={callbackEncontrarEmails}
                      possuiCreditos={creditos > 0}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <DialogActions className={classes.actions}>
        <Tipografia cor="darkSecondaryText">
          {contatos.length
            ? intl.formatMessage(
                { defaultMessage: `Mostrando {numContatos} contatos` },
                { numContatos: contatos.length }
              )
            : ""}
        </Tipografia>
        <Flex>
          <Button className={classes.botao} onClick={cancelarBusca} id="modal-contatos-cancelar">
            {intl.formatMessage({ defaultMessage: "Cancelar" })}
          </Button>
          <Button
            className={classes.botao}
            color="primary"
            onClick={verificarContatosParaAdicionar}
            id="modal-contatos-adicionar"
            disabled={!selecionados}
          >
            {intl.formatMessage({ defaultMessage: "Adicionar" })}
          </Button>
        </Flex>
      </DialogActions>
    </Flex>
  );
}

export default compose(
  injectSheet({
    botao: { "&:hover": { background: "none !important" } },
    botoes: { visibility: "hidden" },
    infoIcon: { color: "gray" },
    countBar: {
      transition: "1s",
      top: 0,
      backgroundColor: "#457ab7",
      height: 56,
      margin: "0 -24px",
      padding: "0 24px",
    },
    icone: {
      width: 36,
      height: 24,
      fontSize: "20px !important",
      "&:hover, &:active": {
        background: "none",
      },
    },
    input: {
      width: "48%",
    },
    root: {
      minHeight: 400,
      minWidth: 756,
      padding: "0 24px 0 24px",
      paddingTop: "0px",
    },
    table: {
      width: "100%",
      minHeight: 240,
      marginLeft: -24,
      marginRight: -24,
      marginBottom: 24,
      paddingLeft: 24,
      paddingRight: 24,
    },
    tableWrapper: {
      display: "flex",
      justifyContent: "center",
      overflowY: "auto",
      maxHeight: 240,
      margin: "0 -24px",
      borderTop: "1px solid lightgray",
    },
    tableRow: {
      verticalAlign: "baseline !important",
      "&:hover $botoes": { visibility: "unset" },
    },
    tableCell: {
      padding: "0px 10px 0 !important",
      borderBottom: "none !important",
    },
    collapse: {
      position: "absolute",
      top: 0,
      margin: "0 -24px",
      padding: "0 24px",
      width: "calc(100% + 48px)",
    },
    actions: {
      justifyContent: "space-between",
      bottom: 0,
      backgroundColor: "white",
    },
    whiteButton: { color: "white" },
    btnAcaoBuscaNaoConcluida: { color: "rgba(244, 67, 54, 1)" },
  })
)(ModalBuscarContatos);
