import React from "react";
import { withStyles } from "@material-ui/core";
import useSWR from "swr";
import { Flex } from "../../../../_common";

function AreaMarketing({ classes }) {
  const { data: banner } = useSWR("/api/pipeline/bannerPublico/Buscar");

  const redirectAcaoBanner = () => {
    window.open(banner?.urlDirecionamento, "_blank").opener = null;
  };

  return (
    <>
      {banner?.urlBanner && (
        <Flex
          flexDirection="column"
          className={classes.containerBanner}
          style={{ backgroundImage: `url(${banner?.urlBanner})` }}
          onClick={redirectAcaoBanner}
        />
      )}
    </>
  );
}

export default withStyles({
  containerBanner: {
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    cursor: "pointer",
  },
})(AreaMarketing);
