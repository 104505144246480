import { defineMessages } from "react-intl";
import env from "../src/../environment";

export const IS_QA_ENV = () => window.location.host.endsWith(".exactsales.dev");
export const IS_DEV_ENV = () => env.NODE_ENV === "development";

export const TIPO_CAMPO_PERSONALIZADO = {
  TEXTO_CURTO: 0,
  TEXTO_LONGO: 1,
  NUMERO: 2,
  ESCOLHA_UNICA: 3,
  ESCOLHA_MULTIPLA: 4,
  LINK: 5,
  isEscolha(tipo) {
    return tipo === this.ESCOLHA_UNICA || tipo === this.ESCOLHA_MULTIPLA;
  },
  isTexto(tipo) {
    return tipo === this.TEXTO_CURTO || tipo === this.TEXTO_LONGO;
  },
  isNumero(tipo) {
    return tipo === this.NUMERO;
  },
};

export const TIPO_CAMPO_PERSONALIZADO_ARRAY = [
  TIPO_CAMPO_PERSONALIZADO.TEXTO_CURTO,
  TIPO_CAMPO_PERSONALIZADO.TEXTO_LONGO,
  TIPO_CAMPO_PERSONALIZADO.NUMERO,
  TIPO_CAMPO_PERSONALIZADO.ESCOLHA_UNICA,
  TIPO_CAMPO_PERSONALIZADO.ESCOLHA_MULTIPLA,
  TIPO_CAMPO_PERSONALIZADO.LINK,
];

export const LABELS_TIPO_CAMPO_PERSONALIZADO = defineMessages({
  [TIPO_CAMPO_PERSONALIZADO.TEXTO_CURTO]: { defaultMessage: "Texto" },
  [TIPO_CAMPO_PERSONALIZADO.TEXTO_LONGO]: { defaultMessage: "Texto longo" },
  [TIPO_CAMPO_PERSONALIZADO.NUMERO]: { defaultMessage: "Número" },
  [TIPO_CAMPO_PERSONALIZADO.ESCOLHA_UNICA]: { defaultMessage: "Escolha única" },
  [TIPO_CAMPO_PERSONALIZADO.ESCOLHA_MULTIPLA]: { defaultMessage: "Escolha múltipla" },
  [TIPO_CAMPO_PERSONALIZADO.LINK]: { defaultMessage: "Link" },
});

export const LABELS_PERIODICIDADE = defineMessages({
  diarias: { defaultMessage: "Diárias" },
  mensais: { defaultMessage: "Mensais" },
  trimestrais: { defaultMessage: "Trimestrais" },
  semestrais: { defaultMessage: "Semestrais" },
  anuais: { defaultMessage: "Anuais" },
});

export const PERIODICIDADE = [
  { id: 1, descricao: "Diárias", label: LABELS_PERIODICIDADE.diarias },
  { id: 3, descricao: "Mensais", label: LABELS_PERIODICIDADE.mensais },
  { id: 4, descricao: "Trimestrais", label: LABELS_PERIODICIDADE.trimestrais },
  { id: 5, descricao: "Semestrais", label: LABELS_PERIODICIDADE.semestrais },
  { id: 6, descricao: "Anuais", label: LABELS_PERIODICIDADE.anuais },
];

export const LABELS_PERIODO = defineMessages({
  dia: { defaultMessage: "Dia" },
  hoje: { defaultMessage: "Hoje" },
  ontem: { defaultMessage: "Ontem" },
  semanaAtual: { defaultMessage: "Semana atual" },
  mesAtual: { defaultMessage: "Mês atual" },
  mes: { defaultMessage: "Mês" },
  mesComercial: { defaultMessage: "Mês comercial" },
});

export const OPCOES_PERIODO = {
  hoje: {
    id: "hoje",
    label: LABELS_PERIODO.hoje,
  },
  ontem: {
    id: "ontem",
    label: LABELS_PERIODO.ontem,
  },
  semana: {
    id: "semana",
    label: LABELS_PERIODO.semanaAtual,
  },
  mes: {
    id: "mes",
    label: LABELS_PERIODO.mesAtual,
  },
};

export const MESES = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const MESES_LABELS = defineMessages({
  Janeiro: { defaultMessage: "Janeiro" },
  Fevereiro: { defaultMessage: "Fevereiro" },
  Março: { defaultMessage: "Março" },
  Abril: { defaultMessage: "Abril" },
  Maio: { defaultMessage: "Maio" },
  Junho: { defaultMessage: "Junho" },
  Julho: { defaultMessage: "Julho" },
  Agosto: { defaultMessage: "Agosto" },
  Setembro: { defaultMessage: "Setembro" },
  Outubro: { defaultMessage: "Outubro" },
  Novembro: { defaultMessage: "Novembro" },
  Dezembro: { defaultMessage: "Dezembro" },
});

export const PERIODO_PADRAO = {
  PERSONALIZADO: 1,
  HOJE: 2,
  ONTEM: 3,
  MES: 4,
  SEMANA: 5,
};

export const LABELS_PERIODO_PADRAO = {
  [PERIODO_PADRAO.PERSONALIZADO]: "personalizado",
  [PERIODO_PADRAO.HOJE]: "hoje",
  [PERIODO_PADRAO.ONTEM]: "ontem",
  [PERIODO_PADRAO.MES]: "mes",
  [PERIODO_PADRAO.SEMANA]: "semana",
};

export const TIPO_IMPEDITIVO = {
  INTEGRACAO: 0,
  PONTUACAO: 1,
  MAPEAMENTO_CAMPOS: 2,
  REGRA_DETECCAO: 3,
  WORKFLOW: 4,
};

export const LABELS_TIPO_IMPEDITIVO = defineMessages({
  [TIPO_IMPEDITIVO.INTEGRACAO]: { defaultMessage: "Integração" },
  [TIPO_IMPEDITIVO.PONTUACAO]: { defaultMessage: "Pontuação" },
  [TIPO_IMPEDITIVO.MAPEAMENTO_CAMPOS]: { defaultMessage: "Mapeamento de campos" },
  [TIPO_IMPEDITIVO.REGRA_DETECCAO]: { defaultMessage: "Regras de detecção" },
  [TIPO_IMPEDITIVO.WORKFLOW]: { defaultMessage: "Workflow" },
});

export const TIPO_QUESTIONARIO = {
  FEEDBACK: 1,
  QUESTIONARIO_LEAD: 2,
  AVALIACAO_USUARIO: 3,
};

export const TIPO_APLICACAO_QUESTIONARIO = {
  PADRAO: 1,
  MANUAL: 2,
  DINAMICO: 3,
};

// TODO remover depois de normalizar nota, temperatura e qualificação
export const notaTemperaturaPraQualificacao = notaTemperatura => Math.abs(notaTemperatura - 6);

export const TEMPERATURA = {
  CONGELADO: 1,
  FRIO: 2,
  MORNO: 3,
  QUENTE: 4,
  MUITO_QUENTE: 5,
};

export const LABELS_TEMPERATURA = defineMessages({
  [TEMPERATURA.CONGELADO]: { defaultMessage: "Congelado" },
  [TEMPERATURA.FRIO]: { defaultMessage: "Frio" },
  [TEMPERATURA.MORNO]: { defaultMessage: "Morno" },
  [TEMPERATURA.QUENTE]: { defaultMessage: "Quente" },
  [TEMPERATURA.MUITO_QUENTE]: { defaultMessage: "Muito Quente" },
});

export const LABELS_TIPO_QUESTIONARIO = defineMessages({
  [TIPO_QUESTIONARIO.FEEDBACK]: { defaultMessage: "Feedback" },
  [TIPO_QUESTIONARIO.QUESTIONARIO_LEAD]: { defaultMessage: "Filtro" },
  [TIPO_QUESTIONARIO.AVALIACAO_USUARIO]: { defaultMessage: "Avaliação Usuario" },
});

export const TIPO_AGENDAMENTO = {
  AGENDAMENTO: 1,
  REAGENDAMENTO: 2,
};

export const DETALHES_METRICAS = {
  CADASTRO_RECUPERACAO: "CadastroRecuperacao",
  TEMPO_LIGACAO: "TempoLigacao",
  LIGACOES_ATENDIDAS: "LigacoesAtendidas",
  TOTAL_LIGACOES: "TotalLigacoes",
  REUNIOES_AGENDADAS: "ReunioesAgendadas",
  REUNIOES_REAGENDADAS: "ReunioesReagendadas",
  REUNIOES_OCORRIDAS: "ReunioesOcorridas",
  FEEDBACKS_PENDENTES: "FeedbacksPendentes",
  FEEDBACKS_RECEBIDOS: "FeedbacksRecebidos",
  REUNIOES_CANCELADAS: "ReunioesCanceladas",
  VENDAS: "Vendas",
  SQL: "SQL",
  REUNIOES_PREVISTAS: "ReunioesPrevistas",
  REUNIOES_NAO_OCORRIDAS: "ReunioesNaoOcorridas",
};

export const LABELS_DETALHES_METRICAS = defineMessages({
  [DETALHES_METRICAS.TEMPO_LIGACAO]: { defaultMessage: "Tempo de ligação" },
  [DETALHES_METRICAS.LIGACOES_ATENDIDAS]: { defaultMessage: "Ligações atendidas" },
  [DETALHES_METRICAS.TOTAL_LIGACOES]: { defaultMessage: "Total de ligações" },
  [DETALHES_METRICAS.REUNIOES_AGENDADAS]: { defaultMessage: "Reuniões agendadas" },
  [DETALHES_METRICAS.REUNIOES_REAGENDADAS]: { defaultMessage: "Reuniões reagendadas" },
  [DETALHES_METRICAS.FEEDBACKS_RECEBIDOS]: { defaultMessage: "Feedbacks recebidos" },
  [DETALHES_METRICAS.REUNIOES_OCORRIDAS]: { defaultMessage: "Reuniões ocorridas" },
  [DETALHES_METRICAS.FEEDBACKS_PENDENTES]: { defaultMessage: "Feedbacks pendentes" },
  [DETALHES_METRICAS.REUNIOES_CANCELADAS]: { defaultMessage: "Reuniões canceladas" },
  [DETALHES_METRICAS.VENDAS]: { defaultMessage: "Vendas" },
  [DETALHES_METRICAS.SQL]: { defaultMessage: "S.Q.L." },
  [DETALHES_METRICAS.CADASTRO_RECUPERACAO]: { defaultMessage: "Cadastro/Recuperação" },
  [DETALHES_METRICAS.REUNIOES_PREVISTAS]: { defaultMessage: "Reuniões previstas" },
  [DETALHES_METRICAS.REUNIOES_NAO_OCORRIDAS]: { defaultMessage: "Reuniões não ocorridas" },
});

export const SITUACAO_LEAD = {
  DESCARTADO: 0,
  EM_OPERACAO: 1,
};

export const SITUACAO_REUNIAO = {
  VIGENTE: 1,
  REAGENDADA: 2,
  CANCELADA: 3,
  CONCLUIDO: 4,
  NAO_OCORREU: 5,
};

export const CONCLUIR_REUNIAO = {
  AGENDAMENTO: 1,
  MOVER_MANUALMENTE: 2,
  MUDAR_FUNIL: 3,
};

export const CONFIRMACAO_REUNIAO = {
  NAO_CONFIRMADAS: false,
  CONFIRMADAS: true,
};

export const LABELS_CONFIRMACAO_REUNIAO = defineMessages({
  [CONFIRMACAO_REUNIAO.NAO_CONFIRMADAS]: { defaultMessage: "Aguardando confirmação" },
  [CONFIRMACAO_REUNIAO.CONFIRMADAS]: { defaultMessage: "Reuniões confirmadas" },
});

export const TIPO_GATE = {
  MANUAL: 1,
  FILTRO: 2,
  AGENDAMENTO: 3,
  VENDA: 4,
};

export const LABELS_TIPO_GATE = defineMessages({
  [TIPO_GATE.MANUAL]: { defaultMessage: "Manual", description: "Tipo gate" },
  [TIPO_GATE.FILTRO]: { defaultMessage: "Filtro", description: "Tipo gate" },
  [TIPO_GATE.AGENDAMENTO]: { defaultMessage: "Agendamento", description: "Tipo gate" },
  [TIPO_GATE.VENDA]: { defaultMessage: "Venda", description: "Tipo gate" },
});

export const SIGLAS_TIPO_GATE = defineMessages({
  [TIPO_GATE.MANUAL]: { defaultMessage: "M", description: "Inicial do gate de saída 'Manual'" },
  [TIPO_GATE.FILTRO]: { defaultMessage: "F", description: "Inicial do gate de saída 'Filtro'" },
  [TIPO_GATE.AGENDAMENTO]: { defaultMessage: "A", description: "Inicial do gate de saída 'Agendamento'" },
  [TIPO_GATE.VENDA]: { defaultMessage: "V", description: "Inicial do gate de saída 'Venda'" },
});

export const PERMISSAO_ETAPA = {
  INTERAGIR: 2,
  VISUALIZAR: 1,
  OCULTAR: 0,
};

export const LABELS_PERMISSAO_ETAPA = defineMessages({
  [PERMISSAO_ETAPA.OCULTAR]: { defaultMessage: "Ocultar" },
  [PERMISSAO_ETAPA.VISUALIZAR]: { defaultMessage: "Visualizar" },
  [PERMISSAO_ETAPA.INTERAGIR]: { defaultMessage: "Interagir" },
});

export const CREDITAR_VENDA = {
  DATA_VENDA: 1,
  MES_ATUAL: 2,
};

export const TIPO_PERFIL = {
  GERENTE: 1,
  PRE_VENDEDOR: 2,
  VENDEDOR: 6,
  GERENTE_ADMIN: 5,
  DESATIVADO: 4,
  ADMIN: 3,
};

export const ROLES = {
  GERENTE: "Gerente",
  PRE_VENDEDOR: "PreVendedor",
  VENDEDOR: "Vendedor",
  GERENTE_ADMIN: "GerenteAdmin",
  DESATIVADO: "Desativado",
  ADMIN: "Admin",
};

export const TIPO_PROMPT_MAGIC_WRITE = {
  PROFILER: 1,
  COMPOSER: 2,
  COMPANION: 3,
  QUALIFIER: 4,
};

export const LABELS_TIPO_PERFIL = defineMessages({
  [TIPO_PERFIL.GERENTE]: { defaultMessage: "Gerente" },
  [TIPO_PERFIL.PRE_VENDEDOR]: { defaultMessage: "Pré-vendedor" },
  [TIPO_PERFIL.VENDEDOR]: { defaultMessage: "Vendedor" },
});
export const STRING_TIPO_PERFIL = {
  [TIPO_PERFIL.GERENTE]: "gerente",
  [TIPO_PERFIL.PRE_VENDEDOR]: "prevendedor",
  [TIPO_PERFIL.VENDEDOR]: "vendedor",
};

export const TIPO_RELATORIO = {
  DADOS_CADASTRAIS: 1,
  CONTATOS: 2,
  CAMPOS_PERSONALIZADOS: 3,
  DORES_SUGERIDAS: 4,
  PERGUNTAS_RESPOSTAS: 5,
  PRODUTOS_SUGERIDOS: 6,
  AGEND_REAGEND_CANCEL: 7,
  CONVERSOES: 8,
};

export const LABELS_TIPO_RELATORIO = defineMessages({
  [TIPO_RELATORIO.DADOS_CADASTRAIS]: { defaultMessage: "Dados cadastrais" },
  [TIPO_RELATORIO.CONTATOS]: { defaultMessage: "Contatos" },
  [TIPO_RELATORIO.CAMPOS_PERSONALIZADOS]: { defaultMessage: "Campos personalizados" },
  [TIPO_RELATORIO.DORES_SUGERIDAS]: { defaultMessage: "Dores sugeridas" },
  [TIPO_RELATORIO.PERGUNTAS_RESPOSTAS]: { defaultMessage: "Perguntas e respostas" },
  [TIPO_RELATORIO.PRODUTOS_SUGERIDOS]: { defaultMessage: "Produtos sugeridos" },
  [TIPO_RELATORIO.AGEND_REAGEND_CANCEL]: { defaultMessage: "Agendamentos/Reagendamentos/Cancelamentos" },
  [TIPO_RELATORIO.CONVERSOES]: { defaultMessage: "Conversões" },
});

export const COLUNAS_AGENDAMENTO = {
  NOME_GRUPO: 46,
  NOME_VENDEDOR: 7,
  QUANTIDADE_AGENDAMENTOS: 47,
};

export const TIPO_SELECAO_VENDEDORES = {
  SELECAO_MANUAL: "1",
  SELECAO_AUTOMATICA: "2",
  DISTRIBUICAO_DE_LEADS: "3",
};

export const CAMPOS_LEADS_SEMELHANTES = {
  LEAD_NOME: "lead_nome",
  LEAD_TELEFONE: "lead_telefone",
  LEAD_EMAIL: "lead_email",
  LEAD_SITE: "lead_site",
  CPF_CNPJ: "lead_cpfCnpj",
  CONTATO_TELEFONE: "contato_telefone",
  CONTATO_EMAIL: "contato_email",
  CONTATO_MENSAGEIRO: "contato_mensageiro",
};

export const API_PUBLICA = {
  isPublic: true,
  URL_MOTIVOS: "/api/pipeline/MotivoReuniaoPublico/Listar",
  URL_QUESTIONARIOS: "/api/pipeline/ReuniaoPublico/BuscarQuestionarios",
  URL_NAO_OCORREU: "/api/pipeline/ReuniaoPublico/NaoOcorreu",
  URL_QUESTIONARIO: "/api/pipeline/QuestionarioPublico/Buscar",
  URL_SALVAR_QUESTIONARIO: "/api/pipeline/ReuniaoPublico/Feedback",
};

export const MARKETING_PLATFORMS = {
  NONE: 0,
  RD_STATION: 14,
  HUBSPOT: 18,
};

export const PHONE_OPERATOR = {
  NONE: 0,
  TOTAL_VOICE: 2,
  DIRECT_CALL: 1,
  TELNET_23: 3,
  U_TECH: 4,
  FLEX: 5,
  FONE_TALK: 6,
  API_PUBLICA: 7,
  WEB_PHONE: 8,
};

export const DISCOUNT_TYPE = {
  NONE: 0,
  PERCENT: 2,
  ABSOLUTE: 1,
};

export const STATUS_INTEGRACAO_AGENDA = {
  SEM_INTEGRACAO: 0,
  INTEGRADO: 1,
  AGUARDANDO_INTEGRACAO: 2,
};

export const TIPO_SERVIDOR = {
  NAO_UTILIZA: "0",
  GOOGLE: "1",
  EXCHANGE: "2",
  YAHOO: "3",
  OFFICE: "4",
};

export const LABELS_TIPO_SERVIDOR = {
  [TIPO_SERVIDOR.NAO_UTILIZA]: "Nenhuma",
  [TIPO_SERVIDOR.GOOGLE]: "Google Calendar",
  [TIPO_SERVIDOR.EXCHANGE]: "",
  [TIPO_SERVIDOR.YAHOO]: "",
  [TIPO_SERVIDOR.OFFICE]: "Office365",
};

export const ESTADO_MINERACAO = {
  SEM_MINERACAO: 0,
  EM_PROGRESSO: 1,
  MINERADO: 2,
};

export const ACCESS_GROUP_TYPE = { USER: "user", PROFILE: "profile" };
export const ACCESS_LEVEL = { INHERIT: -1, NONE: 0, INDIVIDUAL: 1, GROUP: 2, COMPLETE: 3 };

export const TIPO_PERGUNTA = { ESCOLHA_MULTIPLA: 1, ESCOLHA_UNICA: 2, ABERTA: 3 };
export const TIPO_PERGUNTA_LABELS = defineMessages({
  [TIPO_PERGUNTA.ESCOLHA_MULTIPLA]: { defaultMessage: "Escolha múltipla" },
  [TIPO_PERGUNTA.ESCOLHA_UNICA]: { defaultMessage: "Única escolha" },
  [TIPO_PERGUNTA.ABERTA]: { defaultMessage: "Aberta" },
});
export const TIPOS_PERGUNTA = Object.values(TIPO_PERGUNTA);

export const TIPO_FORMATACAO_PERGUNTA = { CPF_CNPJ: 1, MOEDA: 2, DATA: 3 };
export const TIPO_FORMATACAO_LABELS = defineMessages({
  [TIPO_FORMATACAO_PERGUNTA.CPF_CNPJ]: { defaultMessage: "CPF/CNPJ" },
  [TIPO_FORMATACAO_PERGUNTA.MOEDA]: { defaultMessage: "Moeda" },
  [TIPO_FORMATACAO_PERGUNTA.DATA]: { defaultMessage: "Data" },
});
export const TIPOS_FORMATACAO_PERGUNTA = Object.values(TIPO_FORMATACAO_PERGUNTA);

export const TIPO_SCORING = {
  SCORING_E_SIMILARIDADE: "3",
  SCORING_OU_SIMILARIDADE: "4",
};

export const TIPO_META = {
  PERFIL: 0,
  INDIVIDUAL: 1,
};

export const LABELS_TIPO_META = {
  [TIPO_META.PERFIL]: "Perfil",
  [TIPO_META.INDIVIDUAL]: "Individual",
};

export const NU_TIPO_ATIVIDADE_ID = {
  RETORNO_LIGACAO: 1,
  VERIFICAR_DISPONIBILIDADE_REUNIAO: 2,
  ENVIAR_EMAIL: 3,
  OUTROS: 4,
  CONTINUAR_FILTRO: 5,
  PERSONALIZADO: 6,
};

export const INPUT_MAX_LENGTH = {
  ESTADO: 100,
  CIDADE: 100,
  CODIGO_POSTAL: 60,
  LOGRADOURO: 60,
  NUMERO: 60,
  COMPLEMENTO: 14,
  BAIRRO: 45,
};

export const PAIS_BRASIL = {
  id: 1,
  descricao: "Brasil",
};

export const CONJUNTO_DESCRICAO = defineMessages({
  prevendedor: { defaultMessage: "Pré-Vendedor" },
  vendedor: { defaultMessage: "Vendedor" },
  gerente: { defaultMessage: "Gerente" },
});

export const CONJUNTO_ITEM_DESCRICAO = defineMessages({
  prevendedor: { defaultMessage: "Conjunto relativo aos pré-vendedores" },
  vendedor: { defaultMessage: "Conjunto relativo aos vendedores" },
  gerente: { defaultMessage: "Conjunto relativo aos Gerentes" },
});

export const TIPO_ETAPA = {
  DESCARTADO: 0,
  EM_OPERACAO: 1,
};

export const TIPO_DETALHE_DASHBOARD = {
  DESCARTADOS_PRODUTIVIDADE: 0,
  CONVERSOES_PRODUTIVIDADE: 1,
  DESCARTADOS_SAFRA: 2,
  ESTACIONADOS_SAFRA: 3,
  CONVERSOES_SAFRA: 4,
  REINICIADOS_SAFRA: 5,
  SQL: 6,
  RECUSADOS: 7,
};

export const LABELS_TIPO_DETALHE_DASHBOARD = defineMessages({
  [TIPO_DETALHE_DASHBOARD.DESCARTADOS_PRODUTIVIDADE]: { defaultMessage: "Detalhes Descartados" },
  [TIPO_DETALHE_DASHBOARD.CONVERSOES_PRODUTIVIDADE]: { defaultMessage: "Detalhes Conversões" },
  [TIPO_DETALHE_DASHBOARD.DESCARTADOS_SAFRA]: { defaultMessage: "Detalhes Descartados" },
  [TIPO_DETALHE_DASHBOARD.ESTACIONADOS_SAFRA]: { defaultMessage: "Detalhes Estacionados" },
  [TIPO_DETALHE_DASHBOARD.CONVERSOES_SAFRA]: { defaultMessage: "Detalhes Conversões" },
  [TIPO_DETALHE_DASHBOARD.REINICIADOS_SAFRA]: { defaultMessage: "Detalhes Reiniciados" },
  [TIPO_DETALHE_DASHBOARD.SQL]: { defaultMessage: "Detalhes S.Q.L" },
  [TIPO_DETALHE_DASHBOARD.RECUSADOS]: { defaultMessage: "Detalhes Recusados" },
});

export const STATUS_SEARCHING = {
  NOVO_LEAD: 1,
  EM_ANALISE: 2,
  APROVADA: 3,
  REPROVADA: 4,
};

export const BASE_LEGAL = {
  NENHUMA: 0,
  CONSENTIMENTO: 1,
  LEGITIMO_INTERESSE: 2,
  OBRIGACAO_LEGAL_REGULATORIA: 3,
  EXECUCAO_POLITICAS_PUBLICAS: 4,
  EXECUCAO_CONTRATO: 5,
  PROCESSO_JUDICIAL_ADMINISTRATIVO_ARBITRAL: 6,
  PROTECAO_VIDA_INCOLMIDADE_FISICA: 7,
  TUTELA_SAUDE: 8,
  PROTECAO_CREDITO: 9,
};

export const LABELS_BASE_LEGAL = defineMessages({
  [BASE_LEGAL.NENHUMA]: { defaultMessage: "Nenhuma" },
  [BASE_LEGAL.CONSENTIMENTO]: { defaultMessage: "Consentimento" },
  [BASE_LEGAL.LEGITIMO_INTERESSE]: { defaultMessage: "Legítimo Interesse" },
  [BASE_LEGAL.OBRIGACAO_LEGAL_REGULATORIA]: { defaultMessage: "Obrigação legal ou regulatória" },
  [BASE_LEGAL.EXECUCAO_POLITICAS_PUBLICAS]: { defaultMessage: "Execução de políticas públicas" },
  [BASE_LEGAL.EXECUCAO_CONTRATO]: { defaultMessage: "Execução de contrato" },
  [BASE_LEGAL.PROCESSO_JUDICIAL_ADMINISTRATIVO_ARBITRAL]: {
    defaultMessage: "Processo judicial, administrativo ou arbitral",
  },
  [BASE_LEGAL.PROTECAO_VIDA_INCOLMIDADE_FISICA]: { defaultMessage: "Proteção da vida ou incolumidade física" },
  [BASE_LEGAL.TUTELA_SAUDE]: { defaultMessage: "Tutela da saúde" },
  [BASE_LEGAL.PROTECAO_CREDITO]: { defaultMessage: "Proteção do crédito" },
});

export const TIPO_ATIVIDADE = {
  ACESSO_CELULAR: 0,
  ACESSO_DESKTOP: 1,
  DADO_ALTERADO: 2,
  CADASTRO: 3,
};

export const METRICAS = {
  AGENDAMENTO: 1,
  LIGACAO: 2,
  MINUTAGEM: 3,
  VENDA: 4,
  PONTOS: 5,
  SUPER_META: 6,
};

export * from "../pages/dashboard/constants";

export const SITUACAO_REGISTRO = {
  INATIVO: 0,
  ATIVO: 1,
  DELETADO: 2,
  SOMENTELEITURA: 4,
};

export const TIPO_HISTORICO_CONVERSAO = {
  ENTRADA: 1,
  FILTRO: 2,
  REAVALIARFILTRO: 3,
  REUNIAO: 4,
  MOVIMENTOMANUAL: 5,
  DESFAZERMOVIMENTOMANUAL: 6,
  PULARETAPA: 7,
  DESFAZERPULARETAPA: 8,
  RECUPERACAO: 9,
  REINICIARCICLO: 10,
  TRANSFERIRDEFUNIL: 11,
  DESFAZERTRANSFERIRDEFUNIL: 12,
  PULARETAPASEMLOTE: 13,
};

export const STATUS_PROCESSAMENTO = {
  TRANSFERIR_PV: 1,
  TRANSFERIR_VENDEDOR: 2,
  RECUPERAR_LEAD: 3,
  EXCLUIR_LEAD: 4,
};

export const LABELS_STATUS_PROCESSAMENTO = defineMessages({
  [STATUS_PROCESSAMENTO.TRANSFERIR_PV]: { defaultMessage: "Em transferência" },
  [STATUS_PROCESSAMENTO.TRANSFERIR_VENDEDOR]: { defaultMessage: "Em transferência de vendedor" },
  [STATUS_PROCESSAMENTO.RECUPERAR_LEAD]: { defaultMessage: "Em recuperação" },
  [STATUS_PROCESSAMENTO.EXCLUIR_LEAD]: { defaultMessage: "Em exclusão" },
});

export const LABELS_TIPO_RECEITA = defineMessages({
  recorrente: { defaultMessage: "Recorrente" },
  unica: { defaultMessage: "Única" },
});

export const TIPO_RECEITA = [
  { id: 0, descricao: "Recorrente", label: LABELS_TIPO_RECEITA.recorrente },
  { id: 1, descricao: "Única", label: LABELS_TIPO_RECEITA.unica },
];

export const TIPO_FUNCIONALIDADE = {
  NENHUM: 0,
  SEARCHING: 1,
  WEBPHONE: 2,
  BUSCADOR_CONTATOS: 3,
  CADENCIA: 4,
  USUARIOS: 5,
  VIDEO_CONFERENCIA: 6,
  ARMAZENAMENTO_ARQUIVOS: 7,
  WEBPHONE_ILIMITADO: 8,
  WHATSAPP: 9,
  WHATSAPP_HSM: 10,
  EXACT_CLUB: 11,
};

export const TIPO_PLANO_SPOTTER = {
  FULL: 1,
  BASIC: 2,
};

export const TIPO_FEEDBACK_LIGACAO = {
  SUMARIO: 0,
  COMENTARIO: 1,
};

export const ENUM_TIPOS_ANALISE = {
  LIGACAO: "ligacao",
  VIDEO: "videoconferencia",
};

export const PERIODO = {
  DIA: "day",
  SEMANA: "week",
  MES: "month",
};

export const STATUS_PROCESSAMENTO_ATIVIDADE = {
  EXECUCAO: 1,
};

export const LABELS_STATUS_PROCESSAMENTO_ATIVIDADE = defineMessages({
  [STATUS_PROCESSAMENTO_ATIVIDADE.EXECUCAO]: { defaultMessage: "Em execução" },
});

export const TIPO_TIMELINE = {
  EVENTO: 0,
  LOG: 2,
  ATUALIZACAO: 3,
};

export const CODE_DOMINICIAN_REPUBLIC = {
  CODIGO_AREA_809: "1809",
  CODIGO_AREA_829: "1829",
  CODIGO_AREA_849: "1849",
};

export const TIPO_ALERTA_LOGIN = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};
