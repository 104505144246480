import React from "react";
import PropTypes from "prop-types";
import { Dialog, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { ActionButton } from "../../../../../components";
import ModalBuscarContatos from "./ModalBuscarContatos";
import { Flex } from "../../../../../_common";
import PERMISSIONS from "../../../../../_common/permissions";

BuscarContatos.propTypes = {
  lead: PropTypes.object,
  callback: PropTypes.func,
};

function BuscarContatos({ lead, callback, classes }) {
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();
  const toggle = () => setOpen(!open);
  if (!lead) return null;

  function onClose(status) {
    if (status) callback();
    toggle();
  }

  return (
    <Flex className={classes.newLayout}>
      <ActionButton
        id="btn-buscar-contatos"
        type={"button"}
        color="primary"
        label={intl.formatMessage({ defaultMessage: "Buscar Contatos" })}
        onClick={toggle}
        claim={PERMISSIONS.ENCONTRARCONTATOS}
        icon={<PersonAddIcon style={{ marginRight: 4 }} />}
        className={classes.noPadding}
      />
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth disableBackdropClick>
        <ModalBuscarContatos open={open} onClose={onClose} empresa={lead.nome} leadId={lead.id} />
      </Dialog>
    </Flex>
  );
}

export default withStyles(() => ({
  newLayout: { padding: "0px 20px" },
  noPadding: { padding: 0 },
}))(BuscarContatos);
