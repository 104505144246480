import React, { useEffect, useState } from "react";
import { withStyles, Typography, Tooltip, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import EmailIcon from "@material-ui/icons/Email";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";

import ValidacaoEmail from "../ValidacaoEmail";
import { Flex, createSnackbar } from "../../../../../../_common";
import StatusEmail from "../StatusEmail/StatusEmail";
import { useUsuario } from "../../../../../../hooks";
import {
  abrirEscreverEmail as abrirEscreverEmailAction,
  adicionarEmailTo as adicionarEmailToAction,
} from "../../../../../../store/reducers/emails/emailsActions";
import { isAutenticado } from "../../../../../../store/reducers/emails/emails";
import { copyToClipboard } from "../../../../../../_common/utils/string";

const TAMANHO_CARACTER = 9;

function RowEmail({ emailInfo, classes, contato }) {
  const intl = useIntl();
  const { isPVOrVendedor } = useUsuario();
  const history = useHistory();
  const [tooltipText, setTooltipText] = useState("");

  const podeEnviarEmail = useSelector(isAutenticado);

  const dispatch = useDispatch();

  function abrirEscreverEmail() {
    if (isPVOrVendedor && !podeEnviarEmail) {
      history.push("/spotter/central-emails");
    } else {
      dispatch(adicionarEmailToAction(emailInfo));
      dispatch(abrirEscreverEmailAction());
    }
  }

  useEffect(() => {
    montarTooltip();
  }, []);

  const montarTooltip = () => {
    const tamanhoContainer = document.getElementById(`item-email-contato-${emailInfo.id}`).offsetWidth;
    if (emailInfo.email.length > tamanhoContainer / TAMANHO_CARACTER) setTooltipText(emailInfo.email);
    else setTooltipText("");
  };

  const copiar = () => {
    copyToClipboard(emailInfo.email);
    createSnackbar(intl.formatMessage({ defaultMessage: "Copiado" }));
  };

  if (!emailInfo) {
    return null;
  }

  return (
    <Flex className={classes.root}>
      <Tooltip title={tooltipText} classes={{ tooltip: classes.noMaxWidth }}>
        <Flex
          id={`item-email-contato-${emailInfo.id}`}
          alignItems="center"
          className={classes.containerEmail}
          onClick={abrirEscreverEmail}
        >
          <Tooltip
            title={emailInfo.contatoPrincipal ? intl.formatMessage({ defaultMessage: "E-mail principal" }) : ""}
            classes={{ tooltip: classes.noMaxWidth }}
          >
            <EmailIcon
              className={cn(classes.iconeEmail, emailInfo.contatoPrincipal ? classes.principal : classes.secundario)}
            />
          </Tooltip>
          <Typography className={cn(classes.emailBase, classes.principal)}>{emailInfo.email}</Typography>
        </Flex>
      </Tooltip>

      <Flex justifyContent="end" className={classes.containerAcoes}>
        <Tooltip title={intl.formatMessage({ defaultMessage: "Copiar e-mail" })} placement="bottom">
          <FileCopyOutlined
            id="fileCopyOutlined"
            onClick={copiar}
            fontSize="small"
            className={cn(classes.iconeEspacamento, classes.iconeAcao, classes.link)}
          />
        </Tooltip>
        {!emailInfo.probabilidadeEntrega && (
          <>
            {!emailInfo.validando ? (
              <ValidacaoEmail value={emailInfo} contato={contato} botao />
            ) : (
              <Tooltip title="Validando">
                <CircularProgress className={classes.loader} />
              </Tooltip>
            )}
          </>
        )}
        {emailInfo.probabilidadeEntrega && <StatusEmail probabilidadeEntrega={emailInfo.probabilidadeEntrega} />}
      </Flex>
    </Flex>
  );
}

RowEmail.propTypes = {
  emailInfo: PropTypes.object,
  classes: PropTypes.object,
  contato: PropTypes.object,
};

export default withStyles({
  root: {
    marginBottom: 8,
    "&:hover $containerAcoes $iconeAcao": {
      visibility: "visible",
    },
  },
  noMaxWidth: {
    maxWidth: "none",
  },
  containerEmail: {
    width: "70%",
  },
  iconeEmail: { marginRight: 4 },
  principal: { color: "#457AB7" },
  secundario: { color: "rgba(0, 0, 0, 0.38)" },
  emailBase: {
    textDecoration: "none",
    textOverflow: "ellipsis",
    width: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  containerAcoes: {
    width: "30%",
  },
  iconeEspacamento: {
    marginRight: "5px",
  },
  iconeAcao: {
    height: "20px",
    width: "20px",
    color: "rgba(0, 0, 0, 0.38)",
    fill: "rgba(0, 0, 0, 0.38)",
    visibility: "hidden",
    "&:hover": {
      visibility: "visible",
      color: "#457AB7",
      fill: "#457AB7",
    },
  },
  link: {
    cursor: "pointer",
  },
  loader: { height: "16px !important", width: "16px !important", marginRight: 2, marginLeft: 2 },
})(RowEmail);
