import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import { Typography, Button, InputAdornment, IconButton, withStyles } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import styled from "styled-components";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Flex } from "../../_common";
import logoSpotter from "../../_common/icones/logo-login.png";
import AreaMarketing from "./components/AreaMarketing/AreaMarketing";
import useNovoLogin from "./useLogin";
import Alerta from "./components/Alerta/Alerta";
import localStorageManager from "../../_common/utils/storage";
import { RECAPTCHA } from "../../_common/utils/storage/constantesStorage";

const INITIAL_VALUES = {
  nopeEmail: "",
  password: "",
};

export const FormStyle = styled.div`
  #login-password {
    height: 100% !important;
  }
`;

const handleRecaptchaChange = value => {
  if (value == null || value === undefined) {
    localStorageManager.removeItem(RECAPTCHA);
  } else {
    localStorageManager.setItem(RECAPTCHA, value);
  }
};
window.handleRecaptchaChange = handleRecaptchaChange;

function Login({ classes }) {
  const intl = useIntl();
  const [senhaVisivel, setSenhaVisivel] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [dadosAlerta, setDadosAlerta] = useState();
  const [tamanhoTela, setTamanhoTela] = useState(window.innerHeight);

  const {
    configLogin,
    recuperarSenha,
    verificandoEmail,
    mostrarLoginSSO,
    mostrarLogin,
    mostrarMarketing,
    setRecuperarSenha,
    direcionarAcao,
    validarLogin,
    verificarEmail,
  } = useNovoLogin(setMostrarAlerta, setDadosAlerta);

  window.addEventListener("resize", () => {
    setTamanhoTela(window.innerHeight);
  });

  useEffect(
    () => {
      if (configLogin?.habilitaCaptcha && !recuperarSenha) {
        const ID = "ScriptRecaptcha";
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.id = ID;

        document.head.appendChild(script);
      }

      return () => {};
    },
    [configLogin?.habilitaCaptcha, recuperarSenha]
  );

  const handleBlurEmail = (e, funcHandleBlur) => {
    funcHandleBlur(e);
    verificarEmail(e.target.value);
  };

  return (
    <>
      {mostrarLogin && (
        <>
          <Flex flexDirection="column" className={classes.container} style={{ height: tamanhoTela }}>
            <Flex className={cn(classes.logoExact, classes.fullWidth)}>
              <img src={logoSpotter} alt="logo_exact" />
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" className={classes.containerAcoes}>
            <Flex id="area-login" flexDirection="column" className={classes.fullWidth}>
              <Flex
                flexDirection="column"
                className={cn(
                  classes.containerLogin,
                  mostrarMarketing ? classes.posicionamentoContainerLogin : classes.posicionamentoContainerLoginPequeno
                )}
              >
                {!recuperarSenha && (
                  <Typography className={classes.labelBoasVindas}>
                    {intl.formatMessage({ defaultMessage: "Boas-vindas ao Spotter!" })}
                  </Typography>
                )}
                {recuperarSenha && (
                  <>
                    <Typography className={classes.labelBoasVindas}>
                      {intl.formatMessage({ defaultMessage: "Esqueceu sua senha?" })}
                    </Typography>
                    <Typography>
                      {intl.formatMessage({
                        defaultMessage:
                          "Insira o e-mail que você usou ao se cadastrar e enviaremos as instruções para redefinir sua senha.",
                      })}
                    </Typography>
                  </>
                )}

                <Formik
                  enableReinitialize
                  initialValues={INITIAL_VALUES}
                  validate={validarLogin}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(false);
                    if (!verificandoEmail) await direcionarAcao(values);
                  }}
                >
                  {({ handleBlur }) => (
                    <Form autoComplete="off">
                      <FormStyle>
                        <Field
                          id="login-email"
                          name="nopeEmail"
                          fullWidth
                          component={TextField}
                          variant="outlined"
                          autoComplete="nope"
                          label={intl.formatMessage({ defaultMessage: "E-mail" })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            onBlur: e => handleBlurEmail(e, handleBlur),
                          }}
                        />
                        {!recuperarSenha &&
                          !mostrarLoginSSO && (
                            <Field
                              id="login-password"
                              name="password"
                              fullWidth
                              component={TextField}
                              autoComplete="new-password"
                              variant="outlined"
                              type={senhaVisivel ? "text" : "password"}
                              label={intl.formatMessage({ defaultMessage: "Senha" })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => setSenhaVisivel(!senhaVisivel)}>
                                      {senhaVisivel ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                      </FormStyle>
                      {!recuperarSenha &&
                        !mostrarLoginSSO && (
                          <Flex className={classes.containerEsqueciSenha}>
                            <Button type="button" onClick={() => setRecuperarSenha(true)}>
                              {intl.formatMessage({ defaultMessage: "Esqueceu a senha?" })}
                            </Button>
                          </Flex>
                        )}

                      {configLogin?.habilitaCaptcha &&
                        !recuperarSenha && (
                          <div className="row" style={{ marginBottom: "8px" }}>
                            <div className="col-md-12">
                              <div
                                className="g-recaptcha"
                                data-sitekey={configLogin?.chaveCaptcha}
                                data-callback="handleRecaptchaChange"
                                data-expired-callback="handleRecaptchaChange"
                                data-error-callback="handleRecaptchaChange"
                              />
                            </div>
                          </div>
                        )}
                      <Flex className={classes.containerAcessar}>
                        <Button
                          id="login-entrar"
                          variant="contained"
                          type="submit"
                          fullWidth
                          color="primary"
                          className={classes.botaoAcessar}
                        >
                          {!recuperarSenha
                            ? intl.formatMessage({ defaultMessage: "Entrar" })
                            : intl.formatMessage({ defaultMessage: "Redefinir senha" })}
                        </Button>
                      </Flex>
                      {recuperarSenha && (
                        <Flex className={classes.containerVoltarLogin}>
                          <Button type="button" fullWidth onClick={() => setRecuperarSenha(false)}>
                            {intl.formatMessage({ defaultMessage: "Voltar para login" })}
                          </Button>
                        </Flex>
                      )}
                      {mostrarAlerta && (
                        <Flex className={classes.alerta}>
                          <Alerta dadosAlerta={dadosAlerta} setMostrarAlerta={setMostrarAlerta} />
                        </Flex>
                      )}
                    </Form>
                  )}
                </Formik>
              </Flex>
            </Flex>
            {mostrarMarketing && (
              <Flex id="area-marketing" flexDirection="column" className={classes.fullWidth}>
                <AreaMarketing />
              </Flex>
            )}
          </Flex>
        </>
      )}
    </>
  );
}

export default withStyles({
  container: { backgroundColor: "#ffffff" },
  logoExact: { paddingLeft: 80, paddingTop: 80 },
  containerAcoes: { position: "absolute", top: 0, left: 0, right: 0, bottom: 0 },
  labelBoasVindas: { fontSize: 34, fontWeight: 400 },
  containerLogin: { position: "absolute", top: "30%" },
  posicionamentoContainerLogin: { left: 80, width: "32%" },
  posicionamentoContainerLoginPequeno: { left: "20%", width: "60%" },
  iconMostrarSenha: {
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.54)",
  },
  containerEsqueciSenha: {
    marginBottom: 12,
  },
  containerVoltarLogin: {
    marginTop: 12,
  },
  containerAcessar: { marginTop: 16 },
  botaoAcessar: { borderRadius: 4 },
  fullWidth: { width: "100%" },
  alerta: { paddingTop: 20 },
})(Login);
