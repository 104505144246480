import { ROLES, TIPO_PLANO_SPOTTER } from "./constantes";
import { AppSettings } from "./constantesAppSettings";
import permissions from "./permissions";
import { ARQUIVADOS, BUSCAR, ENTRADA, ENVIADOS, IMPORTANTE, LIXEIRA, SPAM } from "../pages/central-emails/constantes";
import {
  TAB_CAMAPANHAS,
  TAB_CAMPANHA_CONTATOS,
  TAB_CAMPANHA_DASHBOARD,
  TAB_CAMPANHA_ENVIOS,
  TAB_CAMPANHA_RESPOSTAS,
  TAB_DESCARTADOS,
  TAB_LISTAS,
  TAB_TEMPLATES,
} from "../pages/cadencia-emails/constantesCadenciaEmails";
import { IdsInlineManual } from "./utils/constants/inlineManual";

export const INFOS_MENU_ROTAS = {
  LOGIN: { url: "/Account/Login", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  NORMALLOGIN: { url: "/Account/NormalLogin", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  LOGINSSO: { url: "/Account/SSOSignIn", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  LOGINSPOTTERADM: { url: "/Account/LoginSpotterAdm", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  ESQUECI_SENHA: { url: "/Account/EsqueciSenha", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  DASHBOARD: {
    url: "/spotter/dashboard",
    icon: "Dashboard",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
    idsInline: { idIcon: IdsInlineManual.DASHBOARD_MENU_ICON, idItem: IdsInlineManual.DASHBOARD_MENU_EXPANDIDO },
  },
  DASHBOARD_PREVENDEDOR: {
    url: "/spotter/dashboard/prevendedor",
    roles: [ROLES.GERENTE, ROLES.PRE_VENDEDOR, ROLES.VENDEDOR, ROLES.ADMIN],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  DASHBOARD_CONFIGURAR: { url: "/spotter/dashboard/configurar", roles: [], claims: [], featureToggles: [] },
  EQUIPE_PRE_VENDEDOR: {
    url: "/spotter/equipe/prevendedor",
    icon: "Person",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
    idsInline: { idIcon: IdsInlineManual.EQUIPE_MENU_ICON, idItem: IdsInlineManual.EQUIPE_MENU_EXPANDIDO },
  },
  EQUIPE: { url: "/spotter/equipe/:currentTab", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  AGENDA_BOXES: {
    url: "/spotter/agenda/boxes",
    icon: "PermContactCalendar",
    roles: [],
    claims: [permissions.AGENDA_VENDEDORES],
    featureToggles: [],
    tipoPlano: null,
    idsInline: { idIcon: IdsInlineManual.AGENDA_MENU_ICON, idItem: IdsInlineManual.AGENDA_MENU_EXPANDIDO },
  },
  AGENDA_CALENDARIO: { url: "/spotter/agenda/calendario", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  AGENDA_PADROES_AGENDAMENTO: {
    url: "/spotter/agenda/padroes-agendamento",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  AGENDA_SOLICITACOES_PREENCHIMENTO: {
    url: "/spotter/agenda/solicitacoes-preenchimento",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  AGENDA_CONFIGURACAO_TAB: {
    url: "/spotter/agenda/configuracao/:tab?",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  LEAD_OPORTUNIDADE: {
    icon: "Work",
    idsInline: {
      idIcon: IdsInlineManual.LEAD_OPORTUNIDADE_MENU_ICON,
      idItem: IdsInlineManual.LEAD_OPORTUNIDADE_MENU_EXPANDIDO,
    },
    accordionItems: [
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_BASE_DE_LEADS_FUNIL",
        url: "/spotter/base-leads/funil",
        roles: [ROLES.GERENTE, ROLES.PRE_VENDEDOR, ROLES.VENDEDOR],
        claims: [],
        featureToggles: [],
        tipoPlano: null,
        idsInline: { idItem: IdsInlineManual.BASE_DE_LEADS_FUNIL_MENU },
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_LISTA_DE_LEADS",
        url: "/spotter/leads",
        roles: [ROLES.GERENTE, ROLES.PRE_VENDEDOR, ROLES.VENDEDOR],
        claims: [],
        featureToggles: [],
        tipoPlano: null,
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_IMPORTAR_LEADS",
        url: "/spotter/leads/Importar",
        roles: [ROLES.GERENTE, ROLES.PRE_VENDEDOR],
        claims: [],
        featureToggles: [],
        tipoPlano: null,
        idsInline: { idIcon: null, idItem: IdsInlineManual.IMPORTAR_LEADS_MENU },
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_RELATORIO_DIST_DE_LEADS",
        url: "/spotter/relatorio-distribuicao-leads",
        roles: [],
        claims: [permissions.RELATORIO_DE_DISTRIBUICAO_DE_LEADS],
        featureToggles: [],
        tipoPlano: TIPO_PLANO_SPOTTER.FULL,
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_ATIVIDADES",
        url: "/spotter/atividades",
        roles: [],
        claims: [permissions.MODULOATIVIDADES],
        featureToggles: [],
        tipoPlano: null,
        idsInline: { idItem: IdsInlineManual.ATIVIDADES_MENU },
      },
    ],
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  BASE_LEADS: { url: "/spotter/base-leads", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  BASE_LEADS_FUNIL_LISTAR: {
    url: "/spotter/base-leads/funil/listar",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  BASE_LEADS_FUNIL_CONFIGURAR: {
    url: "/spotter/base-leads/funil/configurar/:funilId",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  DETALHES_LEAD_LEADID: {
    url: "/spotter/detalhes-lead/:leadId",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  ATIVIDADES_ATIVIDADEID: {
    url: "/spotter/atividades/:atividadeId",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  QUESTIONARIO: { url: "/spotter/questionario", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  EMPRESA_ORGANIZACAO: {
    icon: "Business",
    accordionItems: [
      {
        itemAccordionKey: "EMPRESA_ORGANIZACAO_LISTA_DE_EMPRESAS",
        url: "/spotter/organizacao",
        roles: [],
        claims: [],
        featureToggles: [],
        tipoPlano: null,
      },
      {
        itemAccordionKey: "EMPRESA_ORGANIZACAO_IMPORTAR_EMPRESAS",
        url: "/spotter/organizacao/importar",
        roles: [ROLES.GERENTE, ROLES.PRE_VENDEDOR],
        claims: [],
        featureToggles: [],
        tipoPlano: null,
      },
    ],
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  EMPRESA_ORGID: { url: "/spotter/organizacao/:orgId", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  CENTRAL_EMAILS: {
    url: "/spotter/central-emails/entrada",
    icon: "Mail",
    roles: [ROLES.PRE_VENDEDOR, ROLES.VENDEDOR],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CENTRAL_EMAILS_ID_QUERY: {
    url: `/spotter/central-emails/(${ENTRADA}|${IMPORTANTE}|${ENVIADOS}|${ARQUIVADOS}|${LIXEIRA}|${SPAM}|${BUSCAR})/:idOuQuery?`,
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACAO_CENTRAL_EMAILS_TEMPLATES: {
    url: `/spotter/configuracao/central-emails/templates`,
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  EXACT_CLUB: {
    url: "/spotter/exactclub",
    roles: [],
    claims: [],
    featureToggles: [AppSettings.MenuExactClub],
    tipoPlano: null,
  },
  BA: {
    url: "/spotter/BA/Relatorio",
    icon: "InsertChart",
    roles: [],
    claims: [permissions.BA],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
    idsInline: { idIcon: IdsInlineManual.BA_MENU_ICON, idItem: IdsInlineManual.BA_MENU_EXPANDIDO },
  },
  SEARCHING: {
    url: "/spotter/searching",
    accordionItems: [
      {
        itemAccordionKey: "SEARCHING_LISTAS",
        url: "/spotter/searching/prospect",
        roles: [],
        claims: [permissions.SEARCHING],
        tipoPlano: TIPO_PLANO_SPOTTER.FULL,
      },
      {
        itemAccordionKey: "SEARCHING_EXTRATO",
        url: "/spotter/searching/financeiro",
        roles: [],
        claims: [permissions.SEARCHING],
        tipoPlano: TIPO_PLANO_SPOTTER.FULL,
      },
      {
        itemAccordionKey: "SEARCHING_POTENCIAL_MERCADO",
        url: "/spotter/searching/potencial-mercado",
        roles: [],
        claims: [permissions.SEARCHING],
        tipoPlano: TIPO_PLANO_SPOTTER.FULL,
      },
    ],
    icon: "Style",
    checkFeatureToggleAccordion: true,
    roles: [],
    claims: [permissions.SEARCHING],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  DISCADOR_WEBPHONE: { url: "/webphone/discador", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  CADENCIA_EMAILS_CAMPANHAS: {
    url: "/spotter/cadencia-emails/campanhas",
    icon: "Send",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
    idsInline: {
      idIcon: IdsInlineManual.CADENCIA_EMAILS_MENU_ICON,
      idItem: IdsInlineManual.CADENCIA_EMAILS_MENU_EXPANDIDO,
    },
  },
  CADENCIA_EMAILS: {
    url: "/spotter/cadencia-emails",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_CAMPANHA_CAMPANHAID: {
    url: "/spotter/cadencia-emails/campanha/:campanhaId",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_CAMPANHA_CAMPANHAID_TABDASHBOARD_TABRESPOSTAS_TABENVIOS_TABCONTATOS: {
    url: `/spotter/cadencia-emails/campanha/:campanhaId/(${TAB_CAMPANHA_DASHBOARD}|${TAB_CAMPANHA_RESPOSTAS}|${TAB_CAMPANHA_ENVIOS}|${TAB_CAMPANHA_CONTATOS})`,
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_EDITAR_CAMPANHA: {
    url: "/spotter/cadencia-emails/editar-campanha/:campanhaId",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_NOVA_CAMPANHA: {
    url: "/spotter/cadencia-emails/nova-campanha",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_LISTA_LEADS: {
    url: "/spotter/cadencia-emails/lista-leads/:listaId?",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_LISTA_PROSPECTS: {
    url: "/spotter/cadencia-emails/lista-prospects/:listaId?",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_LISTA_DINAMICA_SEGMENTACAO: {
    url: "/spotter/cadencia-emails/lista-dinamica/segmentacao/:listaId?",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_LISTA_DINAMICA: {
    url: "/spotter/cadencia-emails/lista-dinamica/:listaId?",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_TABCAMPANHAS_TABLISTAS_TABTEMPLATES_TABDESCARTADOS: {
    url: `/spotter/cadencia-emails/(${TAB_CAMAPANHAS}|${TAB_LISTAS}|${TAB_TEMPLATES}|${TAB_DESCARTADOS})`,
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_CONFIGURACOES_DOMINIO: {
    url: "/spotter/cadencia-emails/configuracoes/dominio",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  CADENCIA_EMAILS_CONFIGURACOES: {
    url: "/spotter/cadencia-emails/configuracoes",
    roles: [],
    claims: [permissions.CADENCIA_EMAILS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  SEGMENTACOES: {
    url: "/spotter/segmentacoes",
    icon: "AccountTree",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [AppSettings.RolloutSegmentacao],
    tipoPlano: null,
  },
  PONTUACAO: {
    url: "/spotter/pontuacao",
    icon: "EmojiEvents",
    roles: [ROLES.GERENTE, ROLES.PRE_VENDEDOR],
    claims: [],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  PONTUACAORESUMO: {
    url: "/spotter/pontuacao/resumo",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  EXTRATO_TELEFONIA: {
    url: "/spotter/telefonia/extrato",
    icon: "Phone",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.BASIC,
  },
  TELEFONIA: {
    icon: "Phone",
    accordionItems: [
      {
        itemAccordionKey: "TELEFONIA_PAINEL",
        url: "/spotter/telefonia/painel",
        roles: [ROLES.GERENTE],
        claims: [permissions.PAINEL_TELEFONIA],
        featureToggles: [],
        tipoPlano: null,
      },
      {
        itemAccordionKey: "TELEFONIA_EXTRATO",
        url: "/spotter/telefonia/extrato",
        roles: [],
        claims: [permissions.EXTRATO_TELEFONIA],
        featureToggles: [],
        tipoPlano: null,
      },
      {
        itemAccordionKey: "TELEFONIA_CHATS_WHATSAPP",
        url: "/spotter/lista-chats",
        roles: [ROLES.GERENTE, ROLES.PRE_VENDEDOR],
        claims: [permissions.LISTACHATS],
        featureToggles: [],
        tipoPlano: null,
        customPermission: "MenuWhatsApp",
      },
    ],
    roles: [],
    claims: [permissions.PAINEL_TELEFONIA, permissions.EXTRATO_TELEFONIA, permissions.LISTACHATS],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  WHATSAPP: { url: "/spotter/whatsapp/:tab?", roles: [], claims: [], featureToggles: [] },
  ANALISE_LIGACAO_HISTORICOLIGACAO: {
    url: "/spotter/analise/:tipo?/:historicoLigacaoId?",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  WORKFLOW: {
    url: "/spotter/workflow",
    icon: "SwapCalls",
    roles: [],
    claims: [permissions.WORKFLOW],
    featureToggles: [],
    tipoPlano: null,
    idsInline: { idIcon: IdsInlineManual.WORKFLOW_MENU_ICON, idItem: IdsInlineManual.WORKFLOW_MENU_EXPANDIDO },
  },
  WORKFLOW_EVENTO: { url: "/spotter/workflow/evento", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  WORKFLOW_EVENTO_WORKFLOWID: {
    url: "/spotter/workflow/evento/:workflowId",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  ATIVIDADES_TIPO_ATIVIDADE: {
    url: "/spotter/atividades/tipo-atividade",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: TIPO_PLANO_SPOTTER.FULL,
  },
  BANCO_TALENTOS: {
    url: "/spotter/banco-talentos",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  ATENDIMENTO: { url: "/spotter/atendimento", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  FEEDBACK_REUNIAO: { url: "/spotter/feedback/:reuniaoId", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  REUNIAO: { url: "/spotter/reuniao", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  VIDEO_CONFERENCIA_PLAYER: {
    url: "/spotter/video-conferencia-player",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  RESULTADO_BUSCA_GERAL: {
    url: "/spotter/resultado-busca-geral",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  MENU_CONFIGURAVEL: {
    url: "/spotter/MenuConfiguravel",
    icon: "",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [AppSettings.RolloutMenuConfiguravel],
    tipoPlano: null,
  },
  CONFIGURACOES: {
    url: "/spotter/configuracoes",
    accordionItems: [
      {
        itemAccordionKey: "CONFIGURACOES_PADROES",
        url: "/spotter/configuracoes/padroes",
        roles: [ROLES.VENDEDOR, ROLES.PRE_VENDEDOR, ROLES.GERENTE],
        claims: [
          permissions.DORES,
          permissions.MERCADO,
          permissions.MOTIVO_DESCARTE,
          permissions.PRODUTO,
          permissions.ORIGEM_SUBORIGEM,
          permissions.RESULTADO_DE_LIGACAO,
          permissions.MOTIVO_REUNIAO_NAO_OCORRIDA,
        ],
        featureToggles: [],
        tipoPlano: null,
      },
      {
        itemAccordionKey: "CONFIGURACOES_DISTRIBUICAO_DE_LEADS",
        url: "/spotter/configuracoes/distribuicao-de-leads",
        roles: [ROLES.PRE_VENDEDOR, ROLES.GERENTE],
        claims: [permissions.DISTRIBUICAO_LEADS],
        featureToggles: [],
        tipoPlano: null,
      },
    ],
    icon: "Settings",
    roles: [ROLES.GERENTE],
    checkRoleAccordion: true,
    claims: [],
    featureToggles: [],
    tipoPlano: null,
    idsInline: {
      idIcon: IdsInlineManual.CONFIGURACOES_MENU_ICON,
      idItem: IdsInlineManual.CONFIGURACOES_MENU_EXPANDIDO,
    },
  },
  CONFIGURACOES_HORARIO_COMERCIAL: {
    url: "/spotter/configuracoes/horariocomercial",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_METAS: {
    url: "/spotter/configuracoes/metas",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_INTEGRACOES: {
    url: "/spotter/configuracoes/integracoes",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_REGRAS_DETECCAO: {
    url: "/spotter/configuracoes/regras-deteccao",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_MAPEAMENTO_CAMPOS: {
    url: "/spotter/configuracoes/mapeamentocampos/:plataforma",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_CRITERIOS_ENVIO: {
    url: "/spotter/configuracoes/criteriosenvio/:plataforma",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_ETAPA_ENVIO: {
    url: "/spotter/configuracoes/etapaenvio/:plataforma",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_FILTROS: {
    url: "/spotter/configuracoes/filtros",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_FILTROS_QUESTIONARIOID: {
    url: "/spotter/configuracoes/filtros/:questionarioId",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_CONFIGURACOES_AVANCADAS: {
    url: "/spotter/configuracoes/configuracoes-avancadas",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_CAMPOS_PERSONALIZADOS_LEAD: {
    url: "/spotter/configuracoes/campos-personalizados/lead",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_CAMPOS_PERSONALIZADOS_ORGANIZACAO: {
    url: "/spotter/configuracoes/campos-personalizados/organizacao",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_EMAIL: {
    url: "/spotter/configuracoes/email",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_REGRAS_SEGMENTACAO: {
    url: "/spotter/configuracoes/regras-segmentacao",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_PROTECAO_DADOS: {
    url: "/spotter/configuracoes/protecao-de-dados",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_BASES_LEGAIS: {
    url: "/spotter/configuracoes/configuracao-bases-legais",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONFIGURACOES_ROTEAMENTO_INTELIGENTE: {
    url: "/spotter/configuracoes/roteamento-inteligente",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  MAGIC_COMPANION: { url: "/magic/companion", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  CLIENTES: {
    url: "/spotter/",
    icon: "VerifiedUser",
    roles: [ROLES.ADMIN],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONTA_SUA_CONTA: { url: "/spotter/conta/sua-conta", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  CONTA_MAIS_RECURSOS: {
    url: "/spotter/conta/mais-recursos",
    roles: [ROLES.GERENTE],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONTA_SEGURANCA: { url: "/spotter/conta/seguranca", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  CONTA_TERMOS_DE_USO: {
    url: "/spotter/conta/termos-de-uso",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
  CONTA_FINANCEIRO: { url: "/spotter/conta/financeiro", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  CONTA_WEBPHONE: { url: "/spotter/conta/webphone", roles: [], claims: [], featureToggles: [], tipoPlano: null },
  SEM_PERMISSAO_ACESSO: {
    url: "/spotter/acesso-restrito/sem-permissao-acesso",
    roles: [],
    claims: [],
    featureToggles: [],
    tipoPlano: null,
  },
};
