import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  withStyles,
} from "@material-ui/core";
import useSWR from "swr";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { minerarContato } from "../../../../../../store/reducers/detalhesLead/detalhesLeadActions";
import createSnackAPIException from "../../../../../../_common/utils/snackbar/createSnackbarAPIException";

ModalBuscadorEmail.propTypes = {
  onClose: PropTypes.func,
  minerar: PropTypes.func,
  contato: PropTypes.object,
  leadId: PropTypes.string,
  site: PropTypes.string,
  modalInformativa: PropTypes.bool,
};

const mapStateToProps = state => ({
  site: state.detalhesLead.empresa.site,
});

const mapDispatchToProps = dispatch => ({
  minerar: (contato, leadId, creditos) => dispatch(minerarContato(contato, leadId, creditos)),
});

const MINERACAO_MIN_QTD_NOMES = 2;
const MINERACAO_MIN_TAMANHO_NOME = 3;
function ModalBuscadorEmail({ onClose, minerar, contato, leadId, site, classes, modalInformativa = false }) {
  const intl = useIntl();
  const { data: creditos } = useSWR("/api/pipeline/FuncionalidadePlano/CreditosBuscador");

  let valido;

  const contatoNome = contato.nome || "";
  const nomes = contatoNome.trim().split(" ");
  const temPoucosNomes = nomes.length < MINERACAO_MIN_QTD_NOMES;
  const naoTemSite = !site;
  const [nome, ...sobrenomes] = nomes;
  const nomesMuitoCurtos =
    nome.length < MINERACAO_MIN_TAMANHO_NOME || sobrenomes.join(" ").length < MINERACAO_MIN_TAMANHO_NOME;

  if (temPoucosNomes || naoTemSite || nomesMuitoCurtos) {
    valido = false;
  } else {
    valido = true;
  }

  async function onSave() {
    if (modalInformativa) onClose(true);
    else {
      try {
        await minerar(contato, leadId, creditos);
      } catch (err) {
        createSnackAPIException(err);
      } finally {
        onClose();
      }
    }
  }

  return (
    <React.Fragment>
      <DialogTitle className={classes.noPadding} disableTypography>
        <Typography className={classes.title} variant="h1">
          {intl.formatMessage({ defaultMessage: "Minerador de e-mail" })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container xs alignItems="flex-start" justify="flex-start" className={classes.content}>
            <Grid item container alignItems="flex-start" justify="flex-start">
              {valido ? (
                <div>
                  <DialogContentText>
                    {intl.formatMessage({
                      defaultMessage:
                        "Você realizará a mineração de e-mail deste contato. O crédito cobrado será reembolsado caso não sejam encontrados e-mails válidos.",
                    })}
                  </DialogContentText>

                  <DialogContentText className={classes.creditos}>
                    {intl.formatMessage({ defaultMessage: "Créditos consumidos: 1 de {creditos}." }, { creditos })}
                  </DialogContentText>
                </div>
              ) : (
                <div>
                  <DialogContentText className={classes.errorTitle}>
                    {intl.formatMessage({
                      defaultMessage: "Esta operação não é possível, corrija os erros listados abaixo para prosseguir:",
                    })}
                  </DialogContentText>
                  {temPoucosNomes && (
                    <DialogContentText>
                      {intl.formatMessage({
                        defaultMessage: "- O nome do contato deve ter 2 ou mais palavras",
                      })}
                    </DialogContentText>
                  )}
                  {naoTemSite && (
                    <DialogContentText>
                      {intl.formatMessage({ defaultMessage: "- Adicione um site ao lead" })}
                    </DialogContentText>
                  )}
                  {nomesMuitoCurtos && (
                    <DialogContentText>
                      {intl.formatMessage({
                        defaultMessage: "- Os nomes devem ter no mínimo 3 caracteres",
                      })}
                    </DialogContentText>
                  )}
                </div>
              )}
            </Grid>

            <Grid item />
          </Grid>
        </Grid>
      </DialogContent>
      {valido ? (
        <DialogActions>
          <Button id="cancelar" onClick={() => onClose(!modalInformativa)}>
            {intl.formatMessage({ defaultMessage: "Cancelar" })}
          </Button>
          <Button id="confirmar" onClick={() => onSave()} color="primary">
            {intl.formatMessage({ defaultMessage: "Confirmar" })}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button id="confirmar" onClick={() => onClose(true)} color="primary">
            {intl.formatMessage({ defaultMessage: "Confirmar" })}
          </Button>
        </DialogActions>
      )}
    </React.Fragment>
  );
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles({
    creditos: { padding: "20px 0 0 0" },
    title: { padding: "24px 24px 20px" },
    noPadding: { padding: 0 },
    content: { overflow: "auto", height: "100%" },
    errorTitle: { marginBottom: 16 },
  })
);

export default enhance(ModalBuscadorEmail);
