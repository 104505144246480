import React from "react";
import PropTypes from "prop-types";

import Downshift from "downshift";
import MultiSelectRender from "../MultiSelectRender/MultiSelectRender";

const MultiSelect = ({
  items,
  textDisplayInput,
  handleOnChangeItems,
  menuIsOpen,
  handleChangeMenuIsOpen,
  floatingLabelText,
  searchTerm,
  handleOnChangeSearchTerm,
  allChecked,
  urlCriarNovo,
  urlBuscar,
  callbackCriarNovo,
  width,
}) => (
  <Downshift
    onChange={handleOnChangeItems}
    selectedItem={items.filter(item => item.checked)}
    isOpen={menuIsOpen}
    onOuterClick={() => {
      handleChangeMenuIsOpen(false);
    }}
    itemToString={item => item.descricao}
  >
    {({ getLabelProps, getItemProps, isOpen, selectedItem: selectedItemRender }) => (
      <div>
        <MultiSelectRender
          items={items}
          textDisplayInput={textDisplayInput}
          floatingLabelText={floatingLabelText}
          getLabelProps={getLabelProps}
          getItemProps={getItemProps}
          isOpen={isOpen}
          handleChangeMenuIsOpen={handleChangeMenuIsOpen}
          selectedItem={selectedItemRender}
          width={width}
          searchTerm={searchTerm}
          handleOnChangeSearchTerm={handleOnChangeSearchTerm}
          urlCriarNovo={urlCriarNovo}
          urlBuscar={urlBuscar}
          callbackCriarNovo={callbackCriarNovo}
          allChecked={allChecked}
        />
      </div>
    )}
  </Downshift>
);

MultiSelect.propTypes = {
  /** Items do componente. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      descricao: PropTypes.string.isRequired,
      secondaryText: PropTypes.string,
      checked: PropTypes.bool,
    })
  ).isRequired,
  /** Texto para ser exibido no input */
  textDisplayInput: PropTypes.string.isRequired,
  /**
   * Função chamada no evendo onChange do componente.
   * @param {Array} selectedItems Um array com os itens selecioanados, com a seguinte estrutura: <br/>
   * `[ { id: " ", text: " " } ]`
   */
  handleOnChangeItems: PropTypes.func.isRequired,
  /** Indica se o menu está aberto, se ´true´ = aberto . */
  menuIsOpen: PropTypes.bool,
  /**
   * Função chamada para modificar o estado do componente: aberto = ´true´, fechado = ´false´.
   * @param {bool} open
   */
  handleChangeMenuIsOpen: PropTypes.func.isRequired,
  /** Label do input. */
  floatingLabelText: PropTypes.string,
  /** Texto utilizado como busca na lista dos itens */
  searchTerm: PropTypes.string,
  /**
   * Função chamada para modificar o texto da busca na lista dos itens.
   * @param {string} value
   */
  handleOnChangeSearchTerm: PropTypes.func,
  allChecked: PropTypes.bool,
  /** Largura do componente  */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  urlCriarNovo: PropTypes.string,
  urlBuscar: PropTypes.string,
  callbackCriarNovo: PropTypes.func,
};

MultiSelect.defaultProps = {
  width: 200,
  menuIsOpen: false,
};

export default MultiSelect;
