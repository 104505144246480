import React from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { Grid, Button, DialogTitle, DialogContent, DialogActions, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useIntl } from "react-intl";

import { validarContato } from "../../../../../../store/reducers/detalhesLead/detalhesLeadActions";
import createSnackAPIException from "../../../../../../_common/utils/snackbar/createSnackbarAPIException";

ModalValidacaoEmail.propTypes = {
  onClose: PropTypes.func,
  validar: PropTypes.func,
  value: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  validar: (value, creditos) => dispatch(validarContato(value, creditos)),
});

function ModalValidacaoEmail({ onClose, validar, value }) {
  const intl = useIntl();
  const { data: creditos } = useSWR("/api/pipeline/FuncionalidadePlano/CreditosBuscador");

  async function onSave() {
    try {
      await validar(value, creditos);
    } catch (err) {
      createSnackAPIException(err);
    } finally {
      onClose();
    }
  }

  return (
    <React.Fragment>
      <DialogTitle style={{ padding: 0 }} disableTypography>
        <Typography style={{ padding: "24px 24px 20px" }} variant="h1">
          {intl.formatMessage({ defaultMessage: "Validação de e-mail" })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid
            item
            container
            xs
            alignItems="flex-start"
            justify="flex-start"
            style={{ overflow: "auto", height: "100%" }}
          >
            <Grid item container alignItems="flex-start" justify="flex-start">
              <Typography>
                {intl.formatMessage({
                  defaultMessage:
                    "Você realizará a validação deste e-mail, mas a cobrança de créditos só será feita caso o e-mail seja válido.",
                })}
              </Typography>
              <Typography style={{ padding: "20px 0 0 0" }}>
                {intl.formatMessage({ defaultMessage: "Créditos consumidos: 1 de {creditos}." }, { creditos })}
              </Typography>
            </Grid>
            <Grid item />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(true)}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        <Button onClick={() => onSave()} color="primary">
          {intl.formatMessage({ defaultMessage: "Confirmar" })}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

const enhance = compose(
  connect(
    null,
    mapDispatchToProps
  )
);

export default enhance(ModalValidacaoEmail);
