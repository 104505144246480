import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import axios from "axios";

import { AjaxBlackout, createSnackbar } from "../../_common";
import { TIPO_ALERTA_LOGIN } from "../../_common/constantes";
import env from "../../environment";
import regex from "../../_common/utils/email/regex";
import errorsAuth from "../../_common/utils/auth/errorsAuth";
import {
  INFOS_USUARIO,
  JWT_TOKEN,
  RECAPTCHA,
  REFRESH_TOKEN,
  TIPO_PLANO,
  DASHBOARD_PERIODO_PADRAO,
} from "../../_common/utils/storage/constantesStorage";
import localStorageManager from "../../_common/utils/storage";
import {
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
  errorResponseInterceptor,
} from "../../_common/utils/api/interceptors";
import routes from "../../_common/components/MainRoutes/constantes";
import errorsAuthRedirect from "../../_common/utils/auth/errorsAuthRedirect";
import isLogado from "../../_common/utils/auth/auth";
import { FreshChatWidget, DestroyChat, InitFreshChat } from "../../_common/components/FreshChatWidget/FreshChatWidget";
import { usePageTitle, useQueryParams } from "../../hooks";
import { AddLogDataDog } from "../../_common/utils/startUpApplication/analyticsProvider/datadogBrowserRum";
import { LocalLog, StatusTypeLogDataDog } from "../../_common/utils/startUpApplication/analyticsProvider/constantesLog";

const CONFIG = {
  recaptcha: {
    habilitaCaptcha: false,
    chaveCaptcha: 0,
  },
  password: "",
};

export const TIPO_REUNIAO = {
  VISITA: {
    value: 1,
  },
  ONLINE: {
    value: 2,
  },
  LIGACAO: {
    value: 3,
  },
};

const autenticacaoFalhou = responseLogin => responseLogin?.ExceptionMessage != null;
const contaDesativada = responseLogin => responseLogin?.roles.includes("Desativado");

const checagemEmailFalhou = responseCheckEmail => responseCheckEmail?.ExceptionMessage != null;

function chatWidget() {
  if (window.fcWidget) {
    const estaLogado = isLogado();
    if (!estaLogado) {
      DestroyChat();
      setTimeout(() => {
        InitFreshChat(["login", "v2"], {
          showFAQOnOpen: false,
          hideFAQ: true,
        });
      }, 1500);
    }
  } else {
    FreshChatWidget(["login", "v2"], {
      showFAQOnOpen: false,
      hideFAQ: true,
    });
  }
}

export default function useLogin(setMostrarAlerta, setDadosAlerta) {
  usePageTitle("Login - Exact Sales");
  const intl = useIntl();
  const { param, code } = useQueryParams();
  const baseUrl = env.REACT_APP_BASE_URL;
  const estaLogado = isLogado();
  const [mostrarLogin, setMostrarLogin] = useState(false);
  const [mostrarMarketing, setMostrarMarketing] = useState(true);
  const [configLogin, setConfigLogin] = useState(CONFIG);
  const [recuperarSenha, setRecuperarSenha] = useState(false);
  const [verificandoEmail, setVerificandoEmail] = useState(false);
  const [mostrarLoginSSO, setMostrarLoginSSO] = useState(false);
  const [linkLoginSSO, setLinkLoginSSO] = useState("");
  const emailInformado = useRef("");

  useEffect(() => {
    if (estaLogado) {
      window.location.href = `/spotter/base-leads/funil`;
    } else {
      document.getElementById("body-root").style.margin = 0;
      document.getElementById("divEstiloCondicionalWrapper").style = { position: "unset", padding: 0 };
      const bannerRd = document.getElementById("bannerRD");
      if (bannerRd) bannerRd.style.display = "none";
      chatWidget();
      checkRedirectToTermsOfUse();
      setMostrarMarketing(window.innerWidth > 800);
    }
  }, []);

  window.addEventListener("resize", () => {
    if (!isZooming()) setMostrarMarketing(window.innerWidth > 800);
    else {
      const newPxRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
      if (newPxRatio >= 2) setMostrarMarketing(false);
      else setMostrarMarketing(true);
    }
  });

  let pxRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
  const isZooming = () => {
    const newPxRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (newPxRatio !== pxRatio) {
      pxRatio = newPxRatio;
      return true;
    }
    return false;
  };

  const checkRedirectToTermsOfUse = async () => {
    try {
      if (!param) {
        setMostrarLogin(true);
        return;
      }
      const response = await axios.get(`${baseUrl}/api/pipeline/UsuarioPublico/TermoPublico?param=${param}`);
      if (response.data === true) {
        window.location.href = `/public/criar-senha?param=${param}&code=${code}`;
      }
    } catch (error) {
      setMostrarLogin(true);
      setDadosAlerta({
        severity: TIPO_ALERTA_LOGIN.WARNING,
        texto: intl.formatMessage(
          {
            defaultMessage: "Algo deu errado, tente novamente mais tarde ou {link}",
          },
          {
            link: (
              <a href="mailto:suporte@exactsales.com.br" style={{ textDecoration: "underline" }}>
                {intl.formatMessage({
                  defaultMessage: "entre em contato com o suporte",
                })}
              </a>
            ),
          }
        ),
      });
      setMostrarAlerta(true);
    }
  };

  const acessarSSO = () => {
    AjaxBlackout.Show();
    window.location.href = linkLoginSSO;
  };

  const handleRequestCheckEmailError = error => {
    if (checagemEmailFalhou(error?.response?.data)) {
      try {
        const exceptionMessage = JSON.parse(error?.response?.data?.ExceptionMessage);
        if (exceptionMessage.Error === errorsAuthRedirect.LoginSSO) {
          setMostrarLoginSSO(true);
          const urlExactIdentity = exceptionMessage.UrlExactIdentity;
          const hash = exceptionMessage.Hash;
          setLinkLoginSSO(`${urlExactIdentity}?h=${hash}`);
        }
      } catch {
        AjaxBlackout.Hide();
        const descricaoError = errorsAuth[(error?.response?.data?.ExceptionMessage)];
        setDadosAlerta({
          severity: TIPO_ALERTA_LOGIN.WARNING,
          texto: descricaoError,
        });
      }
    } else {
      AjaxBlackout.Hide();
      const descricaoError = errorsAuth.ErroGenerico;
      setDadosAlerta({
        severity: TIPO_ALERTA_LOGIN.WARNING,
        texto: descricaoError,
      });
    }
  };

  const verificarEmail = async email => {
    if (email && email !== emailInformado.current && regex.EmailFormatoIncorreto.test(email)) {
      emailInformado.current = email;
      setVerificandoEmail(true);
      try {
        const response = await axios.post(`${baseUrl}/api/pipeline/UsuarioPublico/CheckEmail`, null, {
          params: {
            email: decodeURIComponent(email),
          },
        });
        setConfigLogin(response.data);
        setVerificandoEmail(false);
        setMostrarLoginSSO(false);
      } catch (error) {
        console.log(error);
        setVerificandoEmail(false);
        handleRequestCheckEmailError(error);
      }
    }
  };

  const validarLogin = values => {
    const errors = {};
    if (!values.nopeEmail) {
      errors.nopeEmail = errorsAuth.EmailVazio;
    } else if (!regex.EmailFormatoIncorreto.test(values.nopeEmail)) {
      errors.nopeEmail = errorsAuth.EmailFormatoIncorreto;
    } else if (!regex.EmailIncompleto.test(values.nopeEmail)) {
      errors.nopeEmail = errorsAuth.EmailIncompleto;
    } else if (!regex.EmailPosicaoIncorreta.test(values.nopeEmail)) {
      errors.nopeEmail = errorsAuth.EmailPosicaoIncorreta;
    }
    if (!recuperarSenha && !mostrarLoginSSO) {
      if (values.password === "") errors.password = errorsAuth.SenhaObrigatoria;
    }
    return errors;
  };

  const direcionarAcao = values => {
    if (mostrarLoginSSO) acessarSSO();
    else if (recuperarSenha) redefinirSenha(values.nopeEmail);
    else efetuarLogin(values);
  };

  const redefinirSenha = email => {
    AjaxBlackout.Show();
    axios
      .post(`${baseUrl}/api/pipeline/UsuarioPublico/EsqueciSenha`, null, {
        params: {
          email: decodeURIComponent(email),
        },
      })
      .then(() => {
        setMostrarAlerta(true);
        setDadosAlerta({
          severity: TIPO_ALERTA_LOGIN.SUCCESS,
          texto: intl.formatMessage({
            defaultMessage: "E-mail de recuperação enviado! Verifique sua caixa de entrada",
          }),
        });
      })
      .catch(e => {
        console.error(e);
        createSnackbar(intl.formatMessage({ defaultMessage: "A operação falhou" }));
      })
      .finally(() => {
        AjaxBlackout.Hide();
        setRecuperarSenha(false);
      });
  };

  const efetuarLogin = async values => {
    AjaxBlackout.Show();
    try {
      const response = await axios.post(
        `${baseUrl}/api/pipeline/UsuarioPublico/Autorizar`,
        { email: values.nopeEmail, password: values.password },
        {
          headers: {
            "g-recaptcha-response": localStorageManager.getItem(RECAPTCHA),
          },
        }
      );

      const { jwt, refreshToken, refreshTokenDataExpiracao, dashboardPeriodoPadrao } = response.data;
      if (autenticacaoFalhou(response.data)) {
        const message = { email: values.nopeEmail, response: response?.data };
        AddLogDataDog(`${LocalLog.NORMAL_LOGIN}_Failed`, message, StatusTypeLogDataDog.ERROR);

        setMostrarAlerta(true);
        setDadosAlerta({
          severity: TIPO_ALERTA_LOGIN.WARNING,
          texto: errorsAuth[(response.data?.ExceptionMessage)],
        });
        try {
          window.grecaptcha.reset();
        } catch (errorMessage) {
          console.error(errorMessage);
        }
      } else if (contaDesativada(response.data)) {
        const message = { email: values.nopeEmail, response: response?.data };
        AddLogDataDog(`${LocalLog.NORMAL_LOGIN}_Failed`, message, StatusTypeLogDataDog.ERROR);

        setMostrarAlerta(true);
        setDadosAlerta({
          severity: TIPO_ALERTA_LOGIN.WARNING,
          texto: errorsAuth.EmailOuSenhaIncorretos,
        });
        try {
          window.grecaptcha.reset();
        } catch (errorMessage) {
          console.error(errorMessage);
        }
      } else {
        const message = { email: values.nopeEmail };
        AddLogDataDog(`${LocalLog.NORMAL_LOGIN}_Success`, message, StatusTypeLogDataDog.INFO);

        localStorageManager.setItem(JWT_TOKEN, jwt);
        localStorageManager.setItem(INFOS_USUARIO, response.data);
        localStorageManager.setItem(REFRESH_TOKEN, {
          RefreshToken: refreshToken,
          DataExpiracao: refreshTokenDataExpiracao,
        });
        localStorageManager.removeItem(RECAPTCHA);
        axios.defaults.baseURL = env.REACT_APP_BASE_URL;
        axios.interceptors.response.use(responseInterceptor, errorResponseInterceptor);
        axios.interceptors.request.use(requestInterceptor, requestInterceptorError);

        const responseTipoPlano = await axios.get(`${baseUrl}/api/pipeline/Empresa/TipoPlano`);
        localStorageManager.setItem(TIPO_PLANO, responseTipoPlano.data);
        localStorageManager.setItem(DASHBOARD_PERIODO_PADRAO, dashboardPeriodoPadrao);

        window.location.href = `${routes.LEAD_OPORTUNIDADE_BASE_DE_LEADS_FUNIL.route}`;
      }
    } catch (error) {
      AjaxBlackout.Hide();
      handleRequestError(error);
      try {
        window.grecaptcha.reset();
      } catch (errorMessage) {
        console.error(errorMessage);
      }
      const message = { email: values.nopeEmail, response: error?.response?.data };
      AddLogDataDog(`${LocalLog.NORMAL_LOGIN}_Failed`, message, StatusTypeLogDataDog.ERROR);
    }
  };

  const handleRequestError = error => {
    localStorageManager.removeItem(RECAPTCHA);
    if (autenticacaoFalhou(error?.response?.data)) {
      try {
        const exceptionMessage = JSON.parse(error?.response?.data?.ExceptionMessage);
        if (
          exceptionMessage.Error === errorsAuthRedirect.PrecisaMudarSenha ||
          exceptionMessage.Error === errorsAuthRedirect.PrecisaCriarNovaSenha
        ) {
          const tempoExpiracaoEmpresa = exceptionMessage.TempoReset;
          const idPublico = exceptionMessage.Param;
          if (exceptionMessage.Error === errorsAuthRedirect.PrecisaMudarSenha) {
            window.location.href = `/public/trocar-senha?tempoReset=${tempoExpiracaoEmpresa}&param=${idPublico}`;
          } else if (exceptionMessage.Error === errorsAuthRedirect.PrecisaCriarNovaSenha) {
            window.location.href = `/public/criar-senha?tempoReset=${tempoExpiracaoEmpresa}&param=${idPublico}`;
          }
        }
        if (exceptionMessage.Error === errorsAuthRedirect.UsuarioBloqueadoComIntegracaoFinanceira) {
          const cypherText = exceptionMessage.TextoCifrado;
          window.location.href = `/public/painel-financeiro?cypherText=${cypherText}`;
        }
      } catch {
        setMostrarAlerta(true);
        setDadosAlerta({
          severity:
            error?.response?.data?.ExceptionMessage === "QuantidadeLoginExcedido"
              ? TIPO_ALERTA_LOGIN.ERROR
              : TIPO_ALERTA_LOGIN.WARNING,
          texto: errorsAuth[(error?.response?.data?.ExceptionMessage)],
        });
      }
    } else {
      setMostrarAlerta(true);
      setDadosAlerta({
        severity: TIPO_ALERTA_LOGIN.ERROR,
        texto: errorsAuth.ErroGenerico,
      });
    }
  };

  return {
    configLogin,
    recuperarSenha,
    verificandoEmail,
    mostrarLoginSSO,
    mostrarLogin,
    mostrarMarketing,
    setRecuperarSenha,
    direcionarAcao,
    validarLogin,
    verificarEmail,
  };
}
