/**
 * CE = CADENCIA EMAILS
 * como as ações são usadadas apenas no contexto
 * do reducer da própria Cadência de Emails,
 * a abreviação no nome das variáveis poderia ser removida.
 */
export default {
  CE__BUSCAR_LISTAS_AUX_REQUEST: "CADENCIA_EMAILS/BUSCAR_LISTAS_AUX_REQUEST",
  CE__BUSCAR_LISTAS_AUX_RESPONSE: "CADENCIA_EMAILS/BUSCAR_LISTAS_AUX_RESPONSE",
  CE__FECHAR_MODAL_SELECIONA_TIPO_LISTA: "CADENCIA_EMAILS/FECHAR_MODAL_SELECIONA_TIPO_LISTA",
  CE__ABRIR_MODAL_SELECIONA_TIPO_LISTA: "CADENCIA_EMAILS/ABRIR_MODAL_SELECIONA_TIPO_LISTA",
  CE__BUSCAR_LISTAS_REQUEST: "CADENCIA_EMAILS/BUSCAR_LISTAS_REQUEST",
  CE__BUSCAR_LISTAS_RESPONSE: "CADENCIA_EMAILS/BUSCAR_LISTAS_RESPONSE",
  CE__BUSCAR_CAMPANHAS_REQUEST: "CADENCIA_EMAILS/BUSCAR_CAMPANHAS_REQUEST",
  CE__BUSCAR_CAMPANHAS_RESPONSE: "CADENCIA_EMAILS/BUSCAR_CAMPANHAS_RESPONSE",
  CE__BUSCAR_DETALHES_LISTA_RESPONSE: "CADENCIA_EMAILS/BUSCAR_DETALHES_LISTA_RESPONSE",
  CE__BUSCAR_TEMPLATES_REQUEST: "CADENCIA_EMAILS/BUSCAR_TEMPLATES_REQUEST",
  CE__BUSCAR_TEMPLATES_RESPONSE: "CADENCIA_EMAILS/BUSCAR_TEMPLATES_RESPONSE",
  CE__LIMPAR_DETALHES_LISTA: "CADENCIA_EMAILS/LIMPAR_DETALHES_LISTA",
  CE__ON_CHANGE_NOME_LISTA: "CADENCIA_EMAILS/ON_CHANGE_NOME_LISTA",
  CE__EXCLUIR_CONTATO_LISTA: "CADENCIA_EMAILS/EXCLUIR_CONTATO_LISTA",
  CE__BUSCAR_DETALHES_LISTA_REQUEST: "CADENCIA_EMAILS/BUSCAR_DETALHES_LISTA_REQUEST",
  CE__ON_CHANGE_FILTRO_CAMPANHAS: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_CAMPANHAS",
  CE__ON_CHANGE_FILTRO_PREVENDEDORES: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_PREVENDEDORES",
  CE__CHANGE_ORDENACAO: "CADENCIA_EMAILS/CHANGE_ORDENACAO",
  CE__PRE_CARREGAMENTO_NOVA_CAMAPMHA_REQUEST: "CADENCIA_EMAILS/PRE_CARREGAMENTO_NOVA_CAMAPMHA_REQUEST",
  CE__ABRIR_MODAL_NOVO_TEMPLATE: "CADENCIA_EMAILS/ABRIR_MODAL_NOVO_TEMPLATE",
  CE__PRE_CARREGAMENTO_NOVA_CAMAPMHA_RESPONSE: "CADENCIA_EMAILS/PRE_CARREGAMENTO_NOVA_CAMAPMHA_RESPONSE",
  CE__FECHAR_MODAL_NOVO_TEMPLATE: "CADENCIA_EMAILS/FECHAR_MODAL_NOVO_TEMPLATE",
  CE__SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE: "CADENCIA_EMAILS/SET_TEMPLATE_ID_MODAL_NOVO_TEMPLATE",
  CE__SET_READONLY_MODAL_NOVO_TEMPLATE: "CADENCIA_EMAILS/SET_READONLY_MODAL_NOVO_TEMPLATE",
  CE__BUSCAR_RESPOSTAS_REQUEST: "CADENCIA_EMAILS/BUSCAR_RESPOSTAS_REQUEST",
  CE__BUSCAR_RESPOSTAS_RESPONSE: "CADENCIA_EMAILS/BUSCAR_RESPOSTAS_RESPONSE",
  CE__ON_CHANGE_FILTRO_STATUS_RESPOSTA: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_STATUS_RESPOSTA",
  CE__PREENCHE_FILTROS_INICIAIS_DETALHE_CAMPANHA: "CADENCIA_EMAILS/PREENCHE_FILTROS_INICIAIS_DETALHE_CAMPANHA",
  CE__BUSCAR_ENVIOS_REQUEST: "CADENCIA_EMAILS/BUSCAR_ENVIOS_REQUEST",
  CE__BUSCAR_ENVIOS_RESPONSE: "CADENCIA_EMAILS/BUSCAR_ENVIOS_RESPONSE",
  CE__DEFINIR_TODOS_CONTATOS_COMO_SELECIONADOS: "CADENCIA_EMAILS/DEFINIR_TODOS_CONTATOS_COMO_SELECIONADOS",
  CE__DEFINIR_TODOS_CONTATOS_COMO_NAO_SELECIONADOS: "CADENCIA_EMAILS/DEFINIR_TODOS_CONTATOS_COMO_NAO_SELECIONADOS",
  CE__ON_CHANGE_FILTRO_STATUS_ENVIO: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_STATUS_ENVIO",
  CE__ON_CHANGE_FILTRO_ETAPAS_ENVIO: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_ETAPAS_ENVIO",
  CE__ON_CHANGE_MODO_EDICAO_LISTA: "CADENCIA_EMAILS/ON_CHANGE_MODO_EDICAO_LISTA",
  CE__BUSCAR_LISTAS_AUX_DETALHE_CAMPANHA_REQUEST: "CADENCIA_EMAILS/BUSCAR_LISTAS_AUX_DETALHE_CAMPANHA_REQUEST",
  CE__BUSCAR_LISTAS_AUX_DETALHE_CAMPANHA_RESPONSE: "CADENCIA_EMAILS/BUSCAR_LISTAS_AUX_DETALHE_CAMPANHA_RESPONSE",
  CE__LIMPAR_FILTROS_DETALHE_CAMPANHA: "CADENCIA_EMAILS/LIMPAR_FILTROS_DETALHE_CAMPANHA",
  CE__CONFIGURACOES_SETAR_NOME_DOMINIO: "CADENCIA_EMAILS/CONFIGURACOES_SETAR_NOME_DOMINIO",
  CE__ABRIR_MODAL_CAMPANHAS: "CADENCIA_EMAILS/ABRIR_MODAL_CAMPANHAS",
  CE__FECHAR_MODAL_CAMPANHAS: "CADENCIA_EMAILS/FECHAR_MODAL_CAMPANHAS",
  CE__CONVERTER_CONTEUDO_PARA_HTML: "CADENCIA_EMAILS/CONVERTER_CONTEUDO_PARA_HTML",
  CE__CONVERTER_CONTEUDO_PARA_TEXTO: "CADENCIA_EMAILS/CONVERTER_CONTEUDO_PARA_TEXTO",
  CE__ESCREVER_ERRO_DE_VARIAVEIS: "CADENCIA_EMAILS/ESCREVER_ERRO_DE_VARIAVEIS",
  CE__ABRIR_MODAL_CONTATO: "CADENCIA_EMAILS/ABRIR_MODAL_CONTATO",
  CE__FECHAR_MODAL_CONTATO: "CADENCIA_EMAILS/FECHAR_MODAL_CONTATO",
  CE__ADICIONAR_CONTATO_LISTA: "CADENCIA_EMAILS/ADICIONAR_CONTATO_LISTA",
  CE__EDITAR_CONTATO_LISTA: "CADENCIA_EMAILS/EDITAR_CONTATO_LISTA",
  CE__ABRIR_MODAL_IMPORTAR_CONTATOS: "CADENCIA_EMAILS/ABRIR_MODAL_IMPORTAR_CONTATOS",
  CE__FECHAR_MODAL_IMPORTAR_CONTATOS: "CADENCIA_EMAILS/FECHAR_MODAL_IMPORTAR_CONTATOS",
  CE__ABRIR_MODAL_DESCADASTROS: "CADENCIA_EMAILS/ABRIR_MODAL_DESCADASTROS",
  CE__FECHAR_MODAL_DESCADASTROS: "CADENCIA_EMAILS/FECHAR_MODAL_DESCADASTROS",
  CE__BUSCAR_DESCADASTROS_REQUEST: "CADENCIA_EMAILS/BUSCAR_DESCADASTROS_REQUEST",
  CE__BUSCAR_DESCADASTROS_RESPONSE: "CADENCIA_EMAILS/BUSCAR_DESCADASTROS_RESPONSE",
  CE__ON_CLICK_CHECK_DESCADASTRO: "CADENCIA_EMAILS/ON_CLICK_CHECK_DESCADASTRO",
  CE__ON_CLICK_CHECK_ALL_DESCADASTRO: "CADENCIA_EMAILS/ON_CLICK_CHECK_ALL_DESCADASTRO",
  CE__ON_CLICK_CHECK_ENVIO: "CADENCIA_EMAILS/ON_CLICK_CHECK_ENVIO",
  CE__ON_CLICK_CHECK_ALL_ENVIO: "CADENCIA_EMAILS/ON_CLICK_CHECK_ALL_ENVIO",
  CE__ON_CLICK_UNCHECK_ALL_ENVIO: "CADENCIA_EMAILS/ON_CLICK_UNCHECK_ALL_ENVIO",
  CE__FECHAR_MODAL_EXCLUIR_DESCADASTROS_SELECIONADOS: "CADENCIA_EMAILS/FECHAR_MODAL_EXCLUIR_DESCADASTROS_SELECIONADOS",
  CE__ABRIR_MODAL_EXCLUIR_DESCADASTROS_SELECIONADOS: "CADENCIA_EMAILS/ABRIR_MODAL_EXCLUIR_DESCADASTROS_SELECIONADOS",
  CE__IMPORTAR_CONTATOS: "CADENCIA_EMAILS/IMPORTAR_CONTATOS",
  CE__ON_CLICK_CHECKBOX_IMPORTAR_CONTATOS: "CADENCIA_EMAILS/ON_CLICK_CHECKBOX_IMPORTAR_CONTATOS",
  CE__ON_CLICK_CHECKBOX_TODOS_IMPORTAR_CONTATOS: "CADENCIA_EMAILS/ON_CLICK_CHECKBOX_TODOS_IMPORTAR_CONTATOS",
  CE__ADICIONAR_CONTATOS_SELECIONADOS: "CADENCIA_EMAILS/IMPORTAR_CONTATOS_SELECIONADOS",
  CE__FECHAR_MODAL_DISTRIBUIR_ENTRE_PREVENDEDORES: "CADENCIA_EMAILS/FECHAR_MODAL_DISTRIBUIR_ENTRE_PREVENDEDORES",
  CE__ABRIR_MODAL_DISTRIBUIR_ENTRE_PREVENDEDORES: "CADENCIA_EMAILS/ABRIR_MODAL_DISTRIBUIR_ENTRE_PREVENDEDORES",
  CE__ABRIR_MODAL_BUSCAR_CONTATOS_BASE_LEADS: "CADENCIA_EMAILS/ABRIR_MODAL_BUSCAR_CONTATOS_BASE_LEADS",
  CE__FECHAR_MODAL_BUSCAR_CONTATOS_BASE_LEADS: "CADENCIA_EMAILS/FECHAR_MODAL_BUSCAR_CONTATOS_BASE_LEADS",
  CE__BUSCAR_CONTATOS_BASE_LEADS_RESPONSE: "CADENCIA_EMAILS/BUSCAR_CONTATOS_BASE_LEADS_RESPONSE",
  CE__ON_CLICK_CHECKBOX_TODOS_BUSCAR_CONTATOS_BASE_LEADS:
    "CADENCIA_EMAILS/ON_CLICK_CHECKBOX_TODOS_BUSCAR_CONTATOS_BASE_LEADS",
  CE__ON_CLICK_CHECKBOX_BUSCAR_CONTATOS_BASE_LEADS: "CADENCIA_EMAILS/ON_CLICK_CHECKBOX_BUSCAR_CONTATOS_BASE_LEADS",
  CE__INCREMENTAR_PAGINACAO: "CADENCIA_EMAILS/INCREMENTAR_PAGINACAO",
  CE__ZERAR_PAGINACAO: "CADENCIA_EMAILS/ZERAR_PAGINACAO",
  CE__PARAR_PAGINACAO: "CADENCIA_EMAILS/PARAR_PAGINACAO",
  CE__LIMPAR_FILTROS: "CADENCIA_EMAILS/LIMPAR_FILTROS",
  CE__ON_CHANGE_FILTRO_BUSCA: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_BUSCA",
  CE__ON_CHANGE_FILTRO_STATUS: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_STATUS",
  CE__ON_CHANGE_FILTRO_TIPO_LISTA: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_TIPO_LISTA",
  CE__ON_CHANGE_FILTRO_STATUS_LISTA_DINAMICA: "CADENCIA_EMAILS/ON_CHANGE_FILTRO_STATUS_LISTA_DINAMICA",
  CE__SET_CURRENT_EMAIL_VISUALIZACAO: "CADENCIA_EMAIL/SET_CURRENT_EMAIL_VISUALIZACAO",
  CE__SET_INTERESSE_RESPOSTA_ID: "CADENCIA_EMAIL/SET_INTERESSE_RESPOSTA_ID",
  CE__SET_FILTRO_TEMPORARIO: "CADENCIA_EMAIL/SET_FILTRO_TEMPORARIO",
  CE__INJETAR_FILTROS_TEMPORARIOS: "CADENCIA_EMAIL/INJETAR_FILTROS_TEMPORARIOS",
  CE__LIMPAR_FILTROS_TEMPORARIOS: "CADENCIA_EMAIL/LIMPAR_FILTROS_TEMPORARIOS",
  CE__BUSCAR_CAMPANHA_CONTATOS_REQUEST: "CADENCIA_EMAIL/BUSCAR_CAMPANHA_CONTATOS_REQUEST",
  CE__BUSCAR_CAMPANHA_CONTATOS_RESPONSE: "CADENCIA_EMAIL/BUSCAR_CAMPANHA_CONTATOS_RESPONSE",
  CE__SET_DUPLICAR_TEMPLATE_MODAL_NOVO_TEMPLATE: "CADENCIA_EMAILS/SET_DUPLICAR_TEMPLATE_MODAL_NOVO_TEMPLATE",
};
