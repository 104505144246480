import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { withStyles, Tooltip } from "@material-ui/core";
import cn from "classnames";

const STATUS_RETORNO_MENTOR_IA = {
  DESCONHECIDO: 3,
  ALTA: 2,
  MEDIA: 1,
  BAIXA: 0,
};

StatusEmail.propTypes = {
  probabilidadeEntrega: PropTypes.number,
  classes: PropTypes.object,
};

function StatusEmail({ probabilidadeEntrega, classes }) {
  const intl = useIntl();
  const [status, setStatus] = useState();

  useEffect(() => {
    switch (probabilidadeEntrega) {
      case 5:
        setStatus(STATUS_RETORNO_MENTOR_IA.ALTA);
        break;
      case 4:
      case 8:
        setStatus(STATUS_RETORNO_MENTOR_IA.MEDIA);
        break;
      case 7:
        setStatus(STATUS_RETORNO_MENTOR_IA.DESCONHECIDO);
        break;
      default:
        setStatus(STATUS_RETORNO_MENTOR_IA.BAIXA);
        break;
    }
  }, []);

  return (
    <>
      {status === STATUS_RETORNO_MENTOR_IA.ALTA && (
        <Tooltip title={intl.formatMessage({ defaultMessage: "Entregável" })}>
          <span className={cn(classes.badge, classes.alta)} />
        </Tooltip>
      )}
      {status === STATUS_RETORNO_MENTOR_IA.MEDIA && (
        <Tooltip title={intl.formatMessage({ defaultMessage: "Arriscado" })}>
          <span className={cn(classes.badge, classes.media)} />
        </Tooltip>
      )}
      {status === STATUS_RETORNO_MENTOR_IA.BAIXA && (
        <Tooltip title={intl.formatMessage({ defaultMessage: "Não-entregável" })}>
          <span className={cn(classes.badge, classes.baixa)} />
        </Tooltip>
      )}
      {status === STATUS_RETORNO_MENTOR_IA.DESCONHECIDO && (
        <Tooltip title={intl.formatMessage({ defaultMessage: "Desconhecido" })}>
          <span className={cn(classes.badge, classes.desconhecido)} />
        </Tooltip>
      )}
    </>
  );
}

export default withStyles({
  badge: {
    color: "#fff",
    borderRadius: "50%",
    width: 8,
    height: 8,
    marginTop: 6,
  },
  chipLabel: { padding: "3px 4px" },
  desconhecido: { backgroundColor: "#c6c6c6" },
  baixa: { backgroundColor: "#F44336" },
  media: { backgroundColor: "#ED6C02" },
  alta: { backgroundColor: "#0F9D58" },
})(StatusEmail);
