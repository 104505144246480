import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as Icons from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { estadoMenu, menuItems } from "./constantes";
import MenuAccordion from "./components/MenuAccordion";
import logoSpotter from "../../../_common/icones/logo-spotter.png";
import {
  usePerfilPermission,
  useRolloutPermission,
  useClaimPermission,
  useCustomPermission,
  useUsuario,
  useTipoPlanoSpotter,
} from "../../../hooks";
import { AppSettings } from "../../constantesAppSettings";
import { toPascalCase } from "../../utils/string";
import { INFOS_MENU_ROTAS } from "../../constantesMenuRotas";
import routes from "../MainRoutes/constantes";
import { selectUsuarioEstaLogado, usuarioIsGerente } from "../../../store/reducers/usuario/selectors";
import { RD_SUMMIT_STICKBAR } from "../../utils/storage/constantesStorage";
import useLocalStorageListener from "../../../hooks/useLocalStorageListener";

const drawerWidth = 260;
const minDrawerWidth = 50;

const openedMixin = () => ({
  width: drawerWidth,
  transition: "all 0.5s ease",
  overflowX: "hidden",
});

const closedMixin = theme => ({
  transition: "all 0.5s ease",
  overflowX: "hidden",
  width: minDrawerWidth,
  [theme.breakpoints.up("sm")]: {
    width: minDrawerWidth,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "8px 8px 0 8px",
  "& a": {
    "&:focus": {
      outline: "inherit",
      outlineOffset: "inherit",
    },
  },
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open" && prop !== "dimmissRDSummitStickbar" && prop !== "showBannerLogin",
})(({ theme, open, dimmissRDSummitStickbar, showBannerLogin }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      backgroundColor: "#394554",
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      backgroundColor: "#394554",
      ...closedMixin(theme),
    },
  }),
  "& .MuiTypography-root": {
    fontSize: 14,
  },
  "& .MuiPaper-root, .MuiDrawer-paper": {
    borderRight: "none",
  },
  "& .MuiList-root": {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eaeaea",
      borderLeft: "1px solid #ccc",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#aaa",
    },
  },
  "& .MuiList-root, .MuiList-padding": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  ...(showBannerLogin &&
    !dimmissRDSummitStickbar && {
      "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft": {
        top: "68px",
      },
    }),
}));

const ListItemIconStyled = styled(ListItemIcon)(() => ({
  minWidth: 0,
  justifyContent: "center",
  color: "#C4C8CC",
  marginRight: "10px",
}));

const getIconComponent = iconName => {
  const Icon = Icons[iconName];
  return Icon ? <Icon /> : null;
};

const mostrarMenuConfiguravel = (itemMenu, menuConfiguravelUsuario) => {
  const toggle = itemMenu.featureToggles.find(x => x.chave === AppSettings.RolloutMenuConfiguravel.chave);
  const routeMenuConfiguravel = itemMenu.url === INFOS_MENU_ROTAS.MENU_CONFIGURAVEL.url;
  return (
    toggle &&
    routeMenuConfiguravel &&
    menuConfiguravelUsuario?.label?.trim() !== "" &&
    menuConfiguravelUsuario?.label !== undefined &&
    menuConfiguravelUsuario?.icone?.trim() !== "" &&
    menuConfiguravelUsuario?.icone !== undefined &&
    menuConfiguravelUsuario?.iframe?.trim() !== "" &&
    menuConfiguravelUsuario?.iframe !== undefined
  );
};

function Menu() {
  const [menuLateralVisivel, setMenuLateralVisivel] = useState(true);
  const usuarioEstaLogado = useSelector(selectUsuarioEstaLogado);
  const { tipoPlano } = useUsuario();
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const isGerente = useSelector(usuarioIsGerente);
  const dimmissRDSummitStickbar = useLocalStorageListener(RD_SUMMIT_STICKBAR);
  const { featureToggles } = useUsuario();

  useEffect(
    () => {
      if (usuarioEstaLogado !== undefined && !usuarioEstaLogado) {
        setMenuLateralVisivel(false);
      }
    },
    [usuarioEstaLogado]
  );

  const [open, setOpen] = useState(false);

  const [menuState, setMenuState] = useState(estadoMenu.INICIADO);

  const checkIndividualOrGroupLevelWithoutleads = true;

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleEstadoAcordions = estado => {
    setMenuState(() => estado);
  };

  const { menuConfiguravel } = useUsuario();

  const hasPermission = (item, ignorePermissionIfCheckRoleAccordion) => {
    if (!isSpotterFull && item.checkRoleAccordion && !isGerente) {
      return false;
    }

    if (ignorePermissionIfCheckRoleAccordion === false && item.checkRoleAccordion) {
      return true;
    }

    if (item.customPermission && item.claims) {
      return (
        useClaimPermission(item.claims, checkIndividualOrGroupLevelWithoutleads) &&
        useCustomPermission(item.customPermission)
      );
    }

    if (item.customPermission) {
      return useCustomPermission(item.customPermission);
    }
    return (
      usePerfilPermission(item.roles) &&
      useClaimPermission(item.claims, checkIndividualOrGroupLevelWithoutleads) &&
      useRolloutPermission(item.featureToggles)
    );
  };

  const filterItemsWithPermission = items => {
    const itemsFilteredPermissions = items.filter(item => hasPermission(item, false));
    const filteredItems = [];

    for (let i = 0; i < itemsFilteredPermissions.length; i++) {
      const item = itemsFilteredPermissions[i];

      if (mostrarMenuConfiguravel(item, menuConfiguravel)) {
        item.title = menuConfiguravel.label;
        item.icon = toPascalCase(menuConfiguravel.icone);
        filteredItems.push(item);
      } else {
        const filteredAccordionItems = item.accordionItems
          ? item.accordionItems.filter(accordionItem => hasPermission(accordionItem, false))
          : undefined;

        const shouldRemoveAccordionItems =
          item.checkFeatureToggleAccordion && (!filteredAccordionItems || filteredAccordionItems.length === 0);
        const hasRoleAccordionPermission = item.checkRoleAccordion && hasPermission(item, true);

        if (shouldRemoveAccordionItems || hasRoleAccordionPermission) {
          filteredItems.push({ ...item, accordionItems: null });
        } else {
          filteredItems.push({ ...item, accordionItems: filteredAccordionItems });
        }
      }
    }

    return filtrarMenuSpoterBasic(filteredItems);
  };

  const filtrarMenuSpoterBasic = filteredItems => {
    let menusSpoterBasic = filteredItems.filter(x => x.tipoPlano === tipoPlano || x.tipoPlano === null);

    menusSpoterBasic = menusSpoterBasic.map(item => {
      if (item.accordionItems) {
        item.accordionItems = item.accordionItems.filter(
          accItem => accItem.tipoPlano === tipoPlano || accItem.tipoPlano === null
        );
      }
      return item;
    });

    return menusSpoterBasic;
  };

  const filteredMenuItems = filterItemsWithPermission(menuItems(intl));

  return (
    <>
      {menuLateralVisivel && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Drawer
            variant="permanent"
            open={open}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            dimmissRDSummitStickbar={dimmissRDSummitStickbar}
            showBannerLogin={featureToggles.bannerLogin}
          >
            <DrawerHeader sx={{ minHeight: "57px !important" }}>
              <Link to={routes.LEAD_OPORTUNIDADE_BASE_DE_LEADS_FUNIL.route}>
                <img src={logoSpotter} alt="logo_exact" />
              </Link>
            </DrawerHeader>
            <List style={{ overflowY: open ? "auto" : "hidden" }}>
              {filteredMenuItems.map(item => (
                <ListItem key={item.title} disablePadding>
                  {item.accordionItems ? (
                    <MenuAccordion
                      open={open}
                      item={item}
                      getIconComponent={getIconComponent}
                      handleEstadoAcordions={handleEstadoAcordions}
                      menuState={menuState}
                    />
                  ) : (
                    <ListItemButton
                      id={item?.idsInline?.idItem}
                      onClick={() => {
                        handleEstadoAcordions(estadoMenu.FECHADO);
                      }}
                      component={Link}
                      to={item.url}
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        "&:hover": {
                          textDecoration: "none",
                          "& .MuiListItemText-primary": { color: "#fff" },
                          background: "rgba(0, 0, 0, 0.5) !important",
                          borderColor: "rgba(0, 0, 0, 0.5)",
                        },
                      }}
                    >
                      <ListItemIconStyled id={item?.idsInline?.idIcon}>
                        {getIconComponent(item.icon)}
                      </ListItemIconStyled>
                      <ListItemText primary={item.title} sx={{ opacity: 1, color: "#C4C8CC" }} />
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Box>
      )}
    </>
  );
}

export default Menu;
