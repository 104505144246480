import axios from "axios";
import { mutate } from "swr";
import { defineMessages } from "react-intl";

import createSnackAPIException from "../../../_common/utils/snackbar/createSnackbarAPIException";
import createAction from "../../../_common/utils/redux/createAction";
import { objectKeysToCamelCase } from "../../../_common/utils/string";
import { FORBIDDEN } from "../../../_common/utils/constants/httpStatusCodes";
import { ESTADO_MINERACAO } from "../../../_common/constantes";

import { AjaxBlackout, createSnackbar } from "../../../_common";

export const DETALHES_LEAD__BUSCAR_TIMELINE_REQUEST = "DETALHES_LEAD/BUSCAR_TIMELINE_REQUEST";
export const DETALHES_LEAD__BUSCAR_TIMELINE_RESPONSE = "DETALHES_LEAD/BUSCAR_TIMELINE_RESPONSE";
export const DETALHES_LEAD__BUSCAR_TIMELINE_ERROR = "DETALHES_LEAD/BUSCAR_TIMELINE_ERROR";

export const DETALHES_LEAD__BUSCAR_LEAD_REQUEST = "DETALHES_LEAD/BUSCAR_LEAD_REQUEST";
export const DETALHES_LEAD__BUSCAR_LEAD_RESPONSE = "DETALHES_LEAD/BUSCAR_LEAD_RESPONSE";
export const DETALHES_LEAD__BUSCAR_LEAD_ERROR = "DETALHES_LEAD/BUSCAR_LEAD_ERROR";

export const DETALHES_LEAD__ON_CHANGE_FILTRO_FIXO = "DETALHES_LEAD/ON_CHANGE_FILTRO_FIXO";
export const DETALHES_LEAD__ON_CHANGE_FILTRO_DINAMICO = "DETALHES_LEAD/ON_CHANGE_FILTRO_DINAMICO";

export const DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_REQUEST = "DETALHES_LEAD/OPEN_MODAL_DESCARTE_LEAD_REQUEST";
export const DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_RESPONSE = "DETALHES_LEAD/OPEN_MODAL_DESCARTE_LEAD_RESPONSE";
export const DETALHES_LEAD__CLOSE_MODAL_DESCARTE_LEAD = "DETALHES_LEAD/CLOSE_MODAL_DESCARTE_LEAD";

export const DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS = "DETALHES_LEAD/ATUALIZAR_LISTA_CONTATOS";
export const DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS_BLOQUEADOS = "DETALHES_LEAD/ATUALIZAR_LISTA_CONTATOS_BLOQUEADOS";
export const DETALHES_LEAD__ATUALIZAR_CONTATO = "DETALHES_LEAD/ATUALIZAR_CONTATO";
export const DETALHES_LEAD__VALIDAR_CONTATO = "DETALHES_LEAD/VALIDAR_CONTATO";

export const DETALHES_LEAD__ATUALIZAR_LEAD = "DETALHES_LEAD/ATUALIZAR_LEAD";
export const DETALHES_LEAD__FLAG_SHOULD_RELOAD_TIMELINE_LOG = "DETALHES_LEAD/FLAG_SHOULD_RELOAD_TIMELINE_LOG";

export const atualizarLead = lead => createAction(DETALHES_LEAD__ATUALIZAR_LEAD, lead);
export const atualizarListaContatos = contato => createAction(DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS, contato);
export const atualizarListaContatosBloqueados = contato =>
  createAction(DETALHES_LEAD__ATUALIZAR_LISTA_CONTATOS_BLOQUEADOS, contato);
export const atualizarContato = contato => createAction(DETALHES_LEAD__ATUALIZAR_CONTATO, contato);
export const atualizarContatoValidando = contato =>
  createAction(DETALHES_LEAD__VALIDAR_CONTATO, { id: contato.id, validando: true });
export const atualizarContatoValido = (contato, probabilidadeEntrega) =>
  createAction(DETALHES_LEAD__VALIDAR_CONTATO, {
    id: contato.id,
    probabilidadeEntrega,
    validando: false,
  });
export const atualizarContatoInvalido = contato =>
  createAction(DETALHES_LEAD__VALIDAR_CONTATO, { id: contato.id, valido: false, validando: false });

export const buscarTimelineRequest = () => createAction(DETALHES_LEAD__BUSCAR_TIMELINE_REQUEST);
export const buscarTimelineResponse = timeline => createAction(DETALHES_LEAD__BUSCAR_TIMELINE_RESPONSE, timeline);
export const buscarTimelineError = err => createAction(DETALHES_LEAD__BUSCAR_TIMELINE_ERROR, err);

export const buscarLeadRequest = () => createAction(DETALHES_LEAD__BUSCAR_LEAD_REQUEST);
export const buscarLeadResponse = lead => createAction(DETALHES_LEAD__BUSCAR_LEAD_RESPONSE, lead);
export const buscarLeadError = err => createAction(DETALHES_LEAD__BUSCAR_LEAD_ERROR, err);

export const onChangeFiltroFixo = idFiltro => createAction(DETALHES_LEAD__ON_CHANGE_FILTRO_FIXO, idFiltro);
export const onChangeFiltroDinamico = idFiltro => createAction(DETALHES_LEAD__ON_CHANGE_FILTRO_DINAMICO, idFiltro);

export const openModalDescarteLeadRequest = leadId =>
  createAction(DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_REQUEST, leadId);
export const openModalDescarteLeadResponse = motivosDescarte =>
  createAction(DETALHES_LEAD__OPEN_MODAL_DESCARTE_LEAD_RESPONSE, motivosDescarte);
export const closeModalDescarteLead = () => createAction(DETALHES_LEAD__CLOSE_MODAL_DESCARTE_LEAD);

const DETALHE_URL = "/NovoPublic";
export const buscarLeadPublico = leadId => async dispatch => {
  dispatch(buscarLeadRequest());
  try {
    axios.get(`${DETALHE_URL}/${leadId}`).then(({ data: lead }) => dispatch(buscarLeadResponse(lead)));
  } catch (err) {
    dispatch(buscarLeadError());
  }
};

const TIMELINE_URL = `/api/pipeline/leadpublico/timeline`;
export const buscarTimelinePublico = leadId => async dispatch => {
  dispatch(buscarTimelineRequest());
  try {
    axios
      .get(`${TIMELINE_URL}?id=${leadId}`)
      .then(({ data: timeline }) => dispatch(buscarTimelineResponse(objectKeysToCamelCase(timeline))));
  } catch (err) {
    dispatch(buscarTimelineError());
  }
};

export const buscarLead = (leadId, history) => async dispatch => {
  dispatch(buscarLeadRequest());
  try {
    const [resp, campanhas] = await Promise.all([
      axios.get(`/api/pipeline/lead/detalhes`, { params: { id: leadId } }),
      axios.get(`/api/pipeline/lead/campanhas`, { params: { id: leadId } }),
    ]);

    dispatch(buscarLeadResponse({ ...resp.data, id: leadId, Campanhas: campanhas.data }));
  } catch (error) {
    if (error.response && error.response.status === FORBIDDEN && history) {
      history.push("/spotter/acesso-restrito/sem-permissao-acesso");
    }

    dispatch(buscarLeadError(error));
  }
};

export const buscarListaContatos = leadId => async dispatch => {
  try {
    const { data } = await axios.get(`/api/pipeline/lead/contatos?id=${leadId}`);

    dispatch(atualizarListaContatos(data));
  } catch (error) {
    createSnackAPIException(error);
  }
};

export const salvarListaContatos = contatos => async dispatch => {
  try {
    dispatch(atualizarListaContatos(contatos));
  } catch (error) {
    createSnackAPIException(error);
  }
};

export const salvarListaContatosBloqueados = contatosBloqueados => async dispatch => {
  try {
    dispatch(atualizarListaContatosBloqueados(contatosBloqueados));
  } catch (error) {
    createSnackAPIException(error);
  }
};

export const atualizarEmpresa = empresa => async dispatch => {
  try {
    dispatch(atualizarLead(empresa));
  } catch (error) {
    createSnackAPIException(error);
  }
};

const URL_MINERAR = "/api/pipeline/Contatos/MinerarMentorIA";

export const minerarContato = (contato, leadId, creditos) => async dispatch => {
  try {
    const { id } = contato;
    AjaxBlackout.Show();

    axios.post(`${URL_MINERAR}?Id=${id}`).then(() => {
      const obj = {
        ...contato,
        mineracao: ESTADO_MINERACAO.EM_PROGRESSO,
      };
      dispatch(atualizarContato(obj));
      verificarMineracao(id, async () => {
        createSnackbar("Busca de e-mails concluída");
        const { data } = await axios.get(`/api/pipeline/lead/contatos?id=${leadId}`);

        dispatch(atualizarListaContatos(data));
        mutate("/api/pipeline/FuncionalidadePlano/CreditosBuscador");
      });
      mutate("/api/pipeline/FuncionalidadePlano/CreditosBuscador", creditos - 1, false);
    });
  } catch (error) {
    createSnackAPIException(error);
  } finally {
    AjaxBlackout.Hide();
  }
};

const URL_VERIFICAR_MINERACAO = "/api/pipeline/Contatos/SituacaoMineracao";
async function verificarMineracao(id, onMinerado) {
  setTimeout(() => {
    axios
      .get(`${URL_VERIFICAR_MINERACAO}?Id=${id}`)
      .then(
        ({ data: situacao }) =>
          situacao !== ESTADO_MINERACAO.EM_PROGRESSO ? onMinerado() : verificarMineracao(id, onMinerado)
      );
  }, 5000);
}

export const messagesValidarContatos = defineMessages({
  probabilidadeAlta: { defaultMessage: "Probabilidade de entrega: Alta" },
  probabilidadeMedia: { defaultMessage: "Probabilidade de entrega: Média" },
  probabilidadeBaixa: { defaultMessage: "Probabilidade de entrega: Baixa ou Nula" },
});
export const validarContato = (value, creditos, isSpotterFull) => async dispatch => {
  try {
    const probabilidadeEntrega = await realizarValidacao(dispatch, value, creditos, isSpotterFull);
    if (probabilidadeEntrega) {
      switch (probabilidadeEntrega) {
        case 5:
          createSnackbar(messagesValidarContatos.probabilidadeAlta);
          break;
        case 4:
        case 8:
          createSnackbar(messagesValidarContatos.probabilidadeMedia);
          break;
        default:
          createSnackbar(messagesValidarContatos.probabilidadeBaixa);
          break;
      }
    }
  } catch (error) {
    createSnackAPIException(error);
  }
};

const URL_VALIDAR_MENTOR_IA = "/api/pipeline/Email/ValidarStatusDebounceMentorIA";
async function realizarValidacao(dispatch, value, creditos) {
  try {
    dispatch(atualizarContatoValidando(value));

    const { data: probabilidadeEntrega } = await axios.get(
      `${URL_VALIDAR_MENTOR_IA}?endereco=${encodeURIComponent(value.email)}&Id=${value.id}`
    );
    if (probabilidadeEntrega) {
      dispatch(atualizarContatoValido(value, probabilidadeEntrega));
      mutate("/api/pipeline/FuncionalidadePlano/CreditosBuscador", creditos - 1, false);
    } else dispatch(atualizarContatoInvalido(value));
    return probabilidadeEntrega;
  } catch (err) {
    throw err;
  }
}

export const handleOpenModalDescarteLead = leadId => async dispatch => {
  dispatch(openModalDescarteLeadRequest(leadId));
  const resp = await axios.get("/api/pipeline/MotivoDescarte/Listar?somenteAtivos=true");

  const motivosDescarte = objectKeysToCamelCase(resp.data);

  dispatch(openModalDescarteLeadResponse(motivosDescarte));
};

export const handleCloseModalDescarteLead = () => dispatch => {
  dispatch(closeModalDescarteLead());
};
