export const createIntlInfosMenu = intl => ({
  DASHBOARD: {
    title: intl.formatMessage({
      id: "DashboardMenu",
      defaultMessage: "Dashboard",
    }),
  },
  EQUIPE_PRE_VENDEDOR: {
    title: intl.formatMessage({
      defaultMessage: "Equipe",
    }),
  },
  AGENDA_BOXES: {
    title: intl.formatMessage({
      defaultMessage: "Agenda",
    }),
  },
  LEAD_OPORTUNIDADE: {
    title: intl.formatMessage({
      id: "LeadOportunidadeMenu",
      defaultMessage: "Lead/Oportunidade",
    }),
    accordionItems: [
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_BASE_DE_LEADS_FUNIL",
        title: intl.formatMessage({ defaultMessage: "Base de Leads (Funil)" }),
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_LISTA_DE_LEADS",
        title: intl.formatMessage({
          defaultMessage: "Lista de Leads",
        }),
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_IMPORTAR_LEADS",
        title: intl.formatMessage({
          defaultMessage: "Importar Leads",
        }),
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_RELATORIO_DIST_DE_LEADS",
        title: intl.formatMessage({
          defaultMessage: "Relatório Dist. de Leads",
        }),
      },
      {
        itemAccordionKey: "LEAD_OPORTUNIDADE_ATIVIDADES",
        title: intl.formatMessage({
          defaultMessage: "Atividades",
        }),
      },
    ],
  },
  EMPRESA_ORGANIZACAO: {
    title: intl.formatMessage({
      defaultMessage: "Empresa/Organização",
    }),
    accordionItems: [
      {
        itemAccordionKey: "EMPRESA_ORGANIZACAO_LISTA_DE_EMPRESAS",
        title: intl.formatMessage({ defaultMessage: "Lista de Empresas" }),
      },
      {
        itemAccordionKey: "EMPRESA_ORGANIZACAO_IMPORTAR_EMPRESAS",
        title: intl.formatMessage({
          defaultMessage: "Importar Empresas",
        }),
      },
    ],
  },
  CENTRAL_EMAILS: {
    title: intl.formatMessage({
      defaultMessage: "Central de E-mails",
    }),
  },
  BA: {
    title: intl.formatMessage({
      defaultMessage: "Business Analytics",
    }),
  },
  SEARCHING: {
    title: intl.formatMessage({
      id: "SearchingMenu",
      defaultMessage: "Searching",
    }),
    accordionItems: [
      {
        itemAccordionKey: "SEARCHING_LISTAS",
        title: intl.formatMessage({
          defaultMessage: "Listas",
        }),
      },
      {
        itemAccordionKey: "SEARCHING_EXTRATO",
        title: intl.formatMessage({
          defaultMessage: "Extrato",
        }),
      },
      {
        itemAccordionKey: "SEARCHING_POTENCIAL_MERCADO",
        title: intl.formatMessage({
          defaultMessage: "Potencial de Mercado",
        }),
      },
    ],
  },
  CADENCIA_EMAILS_CAMPANHAS: {
    title: intl.formatMessage({
      defaultMessage: "Cadência de E-mails",
    }),
  },
  SEGMENTACOES: {
    title: intl.formatMessage({
      defaultMessage: "Segmentações",
    }),
  },
  PONTUACAO: {
    title: intl.formatMessage({
      defaultMessage: "Pontuação",
    }),
  },
  EXTRATO_TELEFONIA: {
    title: intl.formatMessage({
      defaultMessage: "Extrato de Telefonia",
    }),
  },
  TELEFONIA: {
    title: intl.formatMessage({
      defaultMessage: "Telefonia",
    }),
    accordionItems: [
      {
        itemAccordionKey: "TELEFONIA_PAINEL",
        title: intl.formatMessage({
          defaultMessage: "Painel",
        }),
      },
      {
        itemAccordionKey: "TELEFONIA_EXTRATO",
        title: intl.formatMessage({
          defaultMessage: "Extrato",
        }),
      },
      {
        itemAccordionKey: "TELEFONIA_CHATS_WHATSAPP",
        title: intl.formatMessage({
          defaultMessage: "Lista de chats",
        }),
      },
    ],
  },
  WORKFLOW: {
    title: intl.formatMessage({
      defaultMessage: "Workflow",
    }),
  },
  MENU_CONFIGURAVEL: { title: "" },
  CONFIGURACOES: {
    title: intl.formatMessage({
      defaultMessage: "Configurações",
    }),
    accordionItems: [
      {
        itemAccordionKey: "CONFIGURACOES_PADROES",
        title: intl.formatMessage({
          id: "PadroesMenu",
          defaultMessage: "Padrões",
        }),
      },
      {
        itemAccordionKey: "CONFIGURACOES_DISTRIBUICAO_DE_LEADS",
        title: intl.formatMessage({
          id: "DistribuicaoDeLeadsMenu",
          defaultMessage: "Distribuição de Leads",
        }),
      },
    ],
  },
});
