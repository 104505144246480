import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import MultiSelect from "../../components/MultiSelect/MultiSelect";

class MultiSelectContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      menuIsOpen: false,
      searchTerm: "",
      items: this.props.initialItems || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSelectContatos && prevProps.initialItems !== this.props.initialItems) {
      this.setState({ items: this.props.initialItems || [] });
    }
  }

  getItems = () => this.props.values || this.state.items;

  itemsFiltered = () => {
    if (this.state.searchTerm) {
      return this.getItems().filter(item => item.descricao.toLowerCase().includes(this.state.searchTerm.toLowerCase()));
    }
    return this.getItems();
  };

  textDisplayInput = () => {
    if (this.getItems().every(this.allChecked)) {
      return this.props.intl.formatMessage({ defaultMessage: "Todos" });
    }
    return this.getItems()
      .filter(item => item.checked)
      .map(item => item.descricao)
      .join(", ");
  };

  handleOnChangeItems = item => {
    if (item.id === "checkAll") {
      this.setState({ ...this.state, items: this.changeAllChecked(!item.checked) }, this.callbackHandleOnChangeItems);
    } else {
      const items = [...this.getItems().filter(itemFilter => itemFilter.id !== "checkAll")];

      let itemsAtualizados = items.map(
        itemMap => (itemMap.id !== item.id ? itemMap : { ...itemMap, checked: !itemMap.checked })
      );

      itemsAtualizados = itemsAtualizados.map(
        itemMap =>
          itemMap.id !== "checkAll" ? itemMap : { ...itemMap, checked: itemsAtualizados.every(this.allChecked) }
      );

      this.setState({ ...this.state, items: itemsAtualizados }, this.callbackHandleOnChangeItems);
    }
  };

  callbackHandleOnChangeItems = () => {
    const checkedValues = this.state.items.filter(item => item.checked && item.id !== "checkAll").map(item => item.id);
    const values = this.state.items.filter(item => item.id !== "checkAll");

    this.props.onChange(checkedValues, values);
  };

  changeAllChecked = checked => [...this.getItems().map(item => ({ ...item, checked }))];

  allChecked = item => item.checked;

  handleChangeMenuIsOpen = menuIsOpen => {
    this.setState({ menuIsOpen, searchTerm: "" });

    if (this.props.buscarLista) this.props.buscarLista();
  };

  handleOnChangeSearchTerm = event => {
    this.setState({ searchTerm: event.currentTarget.value });
  };

  callbackCriarNovo = () => {
    this.setState({ menuIsOpen: false });
  };

  render() {
    return (
      <MultiSelect
        items={this.itemsFiltered()}
        textDisplayInput={this.textDisplayInput()}
        handleOnChangeItems={this.handleOnChangeItems}
        menuIsOpen={this.state.menuIsOpen}
        handleChangeMenuIsOpen={this.handleChangeMenuIsOpen}
        floatingLabelText={this.props.floatingLabelText}
        searchTerm={this.state.searchTerm}
        handleOnChangeSearchTerm={this.handleOnChangeSearchTerm}
        allChecked={this.getItems().every(this.allChecked)}
        substituirPlaceholder={this.props.substituirPlaceholder}
        urlCriarNovo={this.props.urlCriarNovo}
        callbackCriarNovo={this.callbackCriarNovo}
        width={this.props.width}
      />
    );
  }
}

MultiSelectContainer.propTypes = {
  /** Items iniciais do componente. UNCONTROLLED */
  initialItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      descricao: PropTypes.string.isRequired,
      secondaryText: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  /** Items do componente. */
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      descricao: PropTypes.string.isRequired,
      secondaryText: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  /** Label do input */
  floatingLabelText: PropTypes.string.isRequired,
  /** Largura do componente */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  substituirPlaceholder: PropTypes.bool,
  urlCriarNovo: PropTypes.string,
  buscarLista: PropTypes.func,
  intl: PropTypes.object,
  isSelectContatos: PropTypes.bool,
};

MultiSelectContainer.defaultProps = {
  width: 250,
};

export default injectIntl(MultiSelectContainer);
